import styled from "styled-components";

export const InfoPanel = styled.div`
  font-family: "DotGothic16", sans-serif;
  color: red;
  width: 100%;
  font-size: 1.25rem;
  letter-spacing: 1px;
  box-shadow: inset 0px 0px 4px white;
  background-color: #222222;
  margin-bottom: 30px;
  border-radius: 8px;
  padding: 15px;
  line-height: 1.5;
  text-align: center;
  text-shadow: 0 0 5px red;
`;
