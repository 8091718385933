import { BarChart, HelpCircle } from "../icons";
import styled from "styled-components";
import { HowToPlayModal, StatsModal } from "./";
import { useState } from "react";
import RadioLogo from "../assets/radiologo.png";

const HeaderWrapper = styled.div`
  margin-bottom: 30px;
  position: relative;
`;

const Title = styled.h1`
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 5px;
`;

const Button = styled.button`
  margin-right: 10px;
  border: 0;
  background: white;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.25s;

  &:hover {
    box-shadow: ;
  }
`;

const RadioLogoWrapper = styled.a`
  position: absolute;
  top: 0;
  right: 0;

  img {
    height: 50px;
    width: auto;
  }
`;

export const Header = () => {
  const [showHowToPlayModal, toggleHowToPlayModal] = useState(
    !localStorage.getItem("howToPlayDismissed")
  );
  const [showStatsModal, toggleStatsModal] = useState(false);

  const closeHowToPlayModal = () => {
    localStorage.setItem("howToPlayDismissed", true);
    toggleHowToPlayModal(false);
  };

  return (
    <HeaderWrapper>
      <RadioLogoWrapper href="https://radio.co" target="_blank">
        <img src={RadioLogo} alt="Radio.co Logo" />
      </RadioLogoWrapper>
      <HowToPlayModal close={closeHowToPlayModal} isOpen={showHowToPlayModal} />
      <StatsModal
        close={() => toggleStatsModal(false)}
        isOpen={showStatsModal}
      />
      <Title>Cuedle</Title>
      <Button onClick={() => toggleHowToPlayModal(true)}>
        <HelpCircle size={20} />
      </Button>
      <Button onClick={() => toggleStatsModal(true)}>
        <BarChart size={20} />
      </Button>
    </HeaderWrapper>
  );
};
