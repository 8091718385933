import { MAX_ATTEMPTS } from "../constants";
import styled, { css } from "styled-components";
import { SongInput } from "./";

export const inputStyles = css`
  min-height: 40px;
  line-height: 40px;
  width: 100%;
  display: block;
  border-radius: 5px;
  border: none;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.4);
  padding: 0 7.5px;
  margin-bottom: 15px;
  background-color: ${({ active }) => (active ? "white" : "#eaeaea")};

  &:focus {
    outline: none;
  }
`;

const PreviousGuess = styled.div`
  ${inputStyles}
  text-align: center;
  color: #666;
`;

const EmptySongInput = styled.div`
  ${inputStyles}
`;

export const Guesses = (props) => {
  const handleChange = (event) =>
    props.setGuess((current) => ({ ...current, value: event.target.value }));

  const submitGuess = (value) =>
    props.setAttempts((existing) => [...existing, { ...props.guess, value }]);

  const handleSubmit = (event) => {
    const { song } = event.currentTarget.dataset;
    submitGuess(song);
  };

  const handleSkip = () => submitGuess(null);

  const renderGuessIcon = (value) => {
    if (!value) return "SKIPPED";
    if (value === props.correctAnswer.label) return "✅";
    return "❌";
  };

  return [...Array(MAX_ATTEMPTS)].map((attempt, i) => {
    if (props.attempts[i]) {
      return (
        <PreviousGuess key={i}>
          {props.attempts[i].value} {renderGuessIcon(props.attempts[i].value)}
        </PreviousGuess>
      );
    }

    if (props.turn === i) {
      return (
        <SongInput
          key={i}
          onChange={handleChange}
          onSubmit={handleSubmit}
          skip={handleSkip}
          songs={props.songs}
          value={props.guess.value}
        />
      );
    }

    return <EmptySongInput key={i} />;
  });
};
