export const Skip = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
  >
    <path d="M337 96v141.8L96 96v320l241-141.8V416h79V96h-79z" />
  </svg>
);
