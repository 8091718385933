export const Eject = ({ size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 8 8"
  >
    <path d="M4 0L0 5h8L4 0zM0 6v2h8V6H0z" />
  </svg>
);
