import { Link } from "react-router-dom";
import styled from "styled-components";

import seventies from "../assets/seventies.jpg";
import eighties from "../assets/eighties.jpg";
import nineties from "../assets/nineties.jpg";
import noughties from "../assets/noughties.jpg";
import twentytens from "../assets/twentytens.jpg";
import twentytwenties from "../assets/twentytwenties.jpg";

const HomeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DecadeTileWrapper = styled(Link)`
  display: block;
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 15px;
  margin-bottom: 30px;
`;

const DecadeTileOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.15s;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
  font-size: 2rem;
  font-weight: bold;
  color: black;
`;

const DecadeTile = styled.div`
  padding-bottom: 100%;
  background: #0f9c92;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-size: cover;

  &:hover ${DecadeTileOverlay} {
    opacity: 1;
  }
`;

export const Home = () => (
  <HomeWrapper>
    <DecadeTileWrapper to="/decade/1970s">
      <DecadeTile style={{ backgroundImage: `url('${seventies}')` }}>
        <DecadeTileOverlay>1970s</DecadeTileOverlay>
      </DecadeTile>
    </DecadeTileWrapper>
    <DecadeTileWrapper to="/decade/1980s">
      <DecadeTile style={{ backgroundImage: `url('${eighties}')` }}>
        <DecadeTileOverlay>1980s</DecadeTileOverlay>
      </DecadeTile>
    </DecadeTileWrapper>
    <DecadeTileWrapper to="/decade/1990s">
      <DecadeTile style={{ backgroundImage: `url('${nineties}')` }}>
        <DecadeTileOverlay>1990s</DecadeTileOverlay>
      </DecadeTile>
    </DecadeTileWrapper>
    <DecadeTileWrapper to="/decade/2000s">
      <DecadeTile style={{ backgroundImage: `url('${noughties}')` }}>
        <DecadeTileOverlay>2000s</DecadeTileOverlay>
      </DecadeTile>
    </DecadeTileWrapper>
    <DecadeTileWrapper to="/decade/2010s">
      <DecadeTile style={{ backgroundImage: `url('${twentytens}')` }}>
        <DecadeTileOverlay>2010s</DecadeTileOverlay>
      </DecadeTile>
    </DecadeTileWrapper>
    <DecadeTileWrapper to="/decade/2020s">
      <DecadeTile style={{ backgroundImage: `url('${twentytwenties}')` }}>
        <DecadeTileOverlay>2020s</DecadeTileOverlay>
      </DecadeTile>
    </DecadeTileWrapper>
  </HomeWrapper>
);
