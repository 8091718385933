export const songs = {
  "1970s": [
    {
      url: "https://soundcloud.com/kiss-official/kiss-rock-and-roll-all-nite?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rock and Roll All Nite - KISS",
    },
    {
      url: "https://soundcloud.com/elton-john/your-song",
      label: "Your Song - Elton John",
    },
    {
      url: "https://soundcloud.com/acdcofficial/highway-to-hell?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Highway to Hell - AC/DC",
    },
    {
      url: "https://soundcloud.com/kate-bush-official/wuthering-heights",
      label: "Wuthering Heights - Kate Bush",
    },
    {
      url: "https://soundcloud.com/aerosmith/dream-on-6?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Dream On - Aerosmith",
    },
    {
      url: "https://soundcloud.com/billyjoel/scenes-from-an-italian",
      label: "Scenes from an Italian Restaurant - Billy Joel",
    },
    {
      url: "https://soundcloud.com/bobdylan/knockin-on-heavens-door-4",
      label: "Knockin' On Heaven's Door - Bob Dylan",
    },
    {
      url: "https://soundcloud.com/foghatofficial/slow-ride-single-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Slow Ride - Foghat",
    },
    {
      url: "https://soundcloud.com/beegees/stayin-alive-7",
      label: "Staying Alive - Bee Gees",
    },
    {
      url: "https://soundcloud.com/the-emotions-official/best-of-my-love-3",
      label: "Best Of My Love - The Emotions",
    },
    {
      url: "https://soundcloud.com/10cc-official/dreadlock-holiday-1",
      label: "Dreadlock Holiday - 10cc",
    },
    {
      url: "https://soundcloud.com/user-529140083/pink-floyd-comfortably-numb",
      label: "Comfortably Numb - Pink Floyd",
    },
    {
      url: "https://soundcloud.com/alicecooperofficial/alice-cooper-schools-out?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "School's Out - Alice Cooper",
    },
    {
      url: "https://soundcloud.com/idaaurora-1/jan-hammer-group-dont-you-know",
      label: "Don't You Know - Jan Hammer Group",
    },
    {
      url: "https://soundcloud.com/elton-john/dont-go-breaking-my-heart",
      label: "Don't Go Breaking My Heart - Elton John",
    },
    {
      url: "https://soundcloud.com/blue-oyster-cult/dont-fear-the-reaper-1",
      label: "(Don't Fear) The Reaper - Blue Öyster Cult",
    },
    {
      url: "https://soundcloud.com/user-806254913/10cc-im-not-in-love-1975",
      label: "I'm Not In Love - 10cc",
    },
    {
      url: "https://soundcloud.com/gloria-gaynor/i-will-survive-3?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Will Survive - Gloria Gaynor",
    },
    {
      url: "https://soundcloud.com/dartsofficial/the-boy-from-new-york-city",
      label: "The Boy From New York City - Darts",
    },
    {
      url: "https://soundcloud.com/abba-official/honey-honey",
      label: "Honey, Honey - ABBA",
    },
    {
      url: "https://soundcloud.com/elton-john/crocodile-rock",
      label: "Crocodile Rock - Elton John",
    },
    {
      url: "https://soundcloud.com/village-people/ymca",
      label: "YMCA - Village People",
    },
    {
      url: "https://soundcloud.com/free67707/all-right-now-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "All Right Now - Free",
    },
    {
      url: "https://soundcloud.com/thewho/wont-get-fooled-again-original?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Won't Get Fooled Again - The Who",
    },
    {
      url: "https://soundcloud.com/beegees/more-than-a-woman-3?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "More Than a Woman - Bee Gees",
    },
    {
      url: "https://soundcloud.com/blondie/heart-of-glass",
      label: "Heart of Glass - Blondie",
    },
    {
      url: "https://soundcloud.com/ramones/blitzkrieg-bop-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Blitzkrieg Bop - Ramones",
    },
    {
      url: "https://soundcloud.com/lou-reed/walk-on-the-wild-side-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Walk on the Wild Side - Lou Reed",
    },
    {
      url: "https://soundcloud.com/boney-m-official/rasputin",
      label: "Rasputin - Boney M",
    },
    {
      url: "https://soundcloud.com/the-allman-brothers-band/jessica-unedited-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Jessica - The Allman Brothers Band",
    },
    {
      url: "https://soundcloud.com/queen-69312/bohemian-rhapsody-remastered-1",
      label: "Bohemian Rhapsody - Queen",
    },
    {
      url: "https://soundcloud.com/jonimitchell/big-yellow-taxi",
      label: "Big Yellow Taxi - Joni Mitchell",
    },
    {
      url: "https://soundcloud.com/baccara-official/yes-sir-i-can-boogie-2",
      label: "Yes Sir I Can Boogie - Baccara",
    },
    {
      url: "https://soundcloud.com/the-osmonds/crazy-horses-album-version",
      label: "Crazy Horses - The Osmonds",
    },
    {
      url: "https://soundcloud.com/slade-official/how-does-it-feel",
      label: "How Does It Feel? - Slade",
    },
    {
      url: "https://soundcloud.com/bob-marley-the-wailers/no-woman-no-cry-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "No Woman No Cry - Bob Marley & the Wailers",
    },
    {
      url: "https://soundcloud.com/fleetwoodmacofficial/dreams-remastered?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Dreams - Fleetwood Mac",
    },
    {
      url: "https://soundcloud.com/kiss-official/love-gun-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Love Gun - KISS",
    },
    {
      url: "https://soundcloud.com/deeppurple/smoke-on-the-water-5?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Smoke on the Water - Deep Purple",
    },
    {
      url: "https://soundcloud.com/iggypopofficial/the-passenger-1",
      label: "The Passenger - Iggy Pop",
    },
    {
      url: "https://soundcloud.com/ledzeppelin/immigrant-song-1",
      label: "Immigrant Song - Led Zeppelin",
    },
    {
      url: "https://soundcloud.com/abba-official/dancing-queen-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Dancing Queen - ABBA",
    },
    {
      url: "https://soundcloud.com/thinlizzyofficial/the-boys-are-back-in-town?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Boys Are Back In Town - Thin Lizzy",
    },
    {
      url: "https://soundcloud.com/bill-withers-official/lovely-day-2",
      label: "Lovely Day - Bill Withers",
    },
    {
      url: "https://soundcloud.com/thinlizzyofficial/jailbreak-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Jailbreak - Thin Lizzy",
    },
    {
      url: "https://soundcloud.com/rolling-stones-official/brown-sugar-remastered?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Brown Sugar - The Rolling Stones",
    },
    {
      url: "https://soundcloud.com/thewho/baba-oriley-original-album?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Baba O'Riley - The Who",
    },
    {
      url: "https://soundcloud.com/davidbowieofficial/rebel-rebel-2016-remastered?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rebel Rebel - David Bowie",
    },
    {
      url: "https://soundcloud.com/talkingheads/this-must-be-the-place-naive-2",
      label: "This Must Be the Place - Talking Heads",
    },
    {
      url: "https://soundcloud.com/daryl-hall-john-oates/sara-smile-2",
      label: "Sara Smile - Daryl Hall & John Oates",
    },
    {
      url: "https://soundcloud.com/sexpistolsofficial/anarchy-in-the-uk-remastered?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Anarchy in the UK - Sex Pistols",
    },
    {
      url: "https://soundcloud.com/stevienicks/edge-of-seventeen",
      label: "Edge of Seventeen - Stevie Nicks",
    },
    {
      url: "https://soundcloud.com/sealsandcrofts/seals-and-crofts-summer-breeze",
      label: "Summer Breeze - Seals and Crofts",
    },
    {
      url: "https://soundcloud.com/trex4/get-it-on?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Get it on - T-Rex",
    },
    {
      url: "https://soundcloud.com/iggypopofficial/lust-for-life-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lust for Life - Iggy Pop",
    },
    {
      url: "https://soundcloud.com/blacksabbath/paranoid?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Paranoid - Black Sabbath",
    },
    {
      url: "https://soundcloud.com/don-mclean-official/american-pie",
      label: "American Pie - Don Mclean",
    },
    {
      url: "https://soundcloud.com/dollyparton/jolene-album-version",
      label: "Jolene - Dolly Parton",
    },
    {
      url: "https://soundcloud.com/wild-cherry-official/play-that-funky-music",
      label: "Play That Funky Music - Wild Cherry",
    },
    {
      url: "https://soundcloud.com/hot-chocolate-official/you-sexy-thing-1",
      label: "You Sexy Thing - Hot Chocolate",
    },
    {
      url: "https://soundcloud.com/davidbowieofficial/life-on-mars-1999-remastered",
      label: "Life on Mars - David Bowie",
    },
    {
      url: "https://soundcloud.com/billyjoel/piano-man-1",
      label: "Piano Man - Billy Joel",
    },
    {
      url: "https://soundcloud.com/jackson-5-official/dancing-machine-3",
      label: "Dancing Machine - Jackson 5",
    },
    {
      url: "https://soundcloud.com/mungo-jerry-official/in-the-summertime",
      label: "In the Summertime - Mungo Jerry",
    },
    {
      url: "https://soundcloud.com/the-boomtown-rats/i-dont-like-mondays-album",
      label: "I Don't Like Mondays - The Boomtown Rats",
    },
    {
      url: "https://soundcloud.com/al-green-official/lets-stay-together-5",
      label: "Let's Stay Together - Al Green",
    },
    {
      url: "https://soundcloud.com/theclash/london-calling-remastered",
      label: "London Calling - The Clash",
    },
    {
      url: "https://soundcloud.com/thekinks/lola-coca-cola-version",
      label: "Lola - The Kinks",
    },
    {
      url: "https://soundcloud.com/davidbowieofficial/heroes-single-version-2014",
      label: "Heroes - David Bowie",
    },
    {
      url: "https://soundcloud.com/gladys-knight-the-pips/midnight-train-to-georgia-7",
      label: "Midnight Train to Georgia - Gladys Knight & The Pips",
    },
    {
      url: "https://soundcloud.com/donna-summer-official/i-feel-love-4",
      label: "I Feel Love - Donna Summer",
    },
    {
      url: "https://soundcloud.com/johnny-nash-official/i-can-see-clearly-now",
      label: "I Can See Clearly Now - Johnny Nash",
    },
    {
      url: "https://soundcloud.com/anita-ward-official/ring-my-bell-42",
      label: "Ring My Bell - Anita Ward",
    },
    {
      url: "https://soundcloud.com/boston-official/more-than-a-feeling-1",
      label: "More Thank a Feeling - Boston",
    },
    {
      url: "https://soundcloud.com/prince/i-wanna-be-your-lover",
      label: "I Wanna Be Your Lover - Prince",
    },
    {
      url: "https://soundcloud.com/chicofficial/chic-good-times",
      label: "Good Times - Chic",
    },
    {
      url: "https://soundcloud.com/sistersledge/we-are-family",
      label: "We Are Family - Sister Sledge",
    },
    {
      url: "https://soundcloud.com/paulmccartney/1-band-on-the-run-16-44-1",
      label: "Band on the Run - Paul McCartney",
    },
    {
      url: "https://soundcloud.com/freda-payne-official/band-of-gold",
      label: "Band of Gold - Freda Payne",
    },
    {
      url: "https://soundcloud.com/talkingheads/psycho-killer",
      label: "Psycho Killer - Talking Heads",
    },
    {
      url: "https://soundcloud.com/john-lennon-official/imagine-2010-remaster",
      label: "Imagine - John Lennon",
    },
    {
      url: "https://soundcloud.com/van-mccoy-the-soul-city-symphony/the-hustle",
      label: "The Hustle - Van McCoy",
    },
    {
      url: "https://soundcloud.com/robertaflackofficial/roberta-flack-killing-me",
      label: "Killing Me Softly With His Song -  Roberta Flack",
    },
    {
      url: "https://soundcloud.com/abba-official/knowing-me-knowing-you",
      label: "Knowing Me, Knowing You - ABBA",
    },
    {
      url: "https://soundcloud.com/barry-manilow-official/mandy",
      label: "Mandy - Barry Manilow",
    },
    {
      url: "https://soundcloud.com/stevie-wonder-official/boogie-on-reggae-woman-2",
      label: "Boogie On Reggae Woman - Stevie Wonder",
    },
    {
      url: "https://soundcloud.com/neil-diamond/i-am-i-said",
      label: "I Am... I Said - Neil Diamond",
    },
    {
      url: "https://soundcloud.com/smokey-robinson-the-miracles/the-tears-of-a-clown-2",
      label: "The Tears of a Clown - Smokey Robinson & The Miracles",
    },
    {
      url: "https://soundcloud.com/stevie-wonder-official/living-for-the-city",
      label: "Living For the City - Stevie Wonder",
    },
    {
      url: "https://soundcloud.com/abba-official/fernando-2",
      label: "Fernando - ABBA",
    },
    {
      url: "https://soundcloud.com/roxy-music-official/love-is-the-drug-1999-remaster",
      label: "Love Is the Drug - Roxy Music",
    },
    {
      url: "https://soundcloud.com/war-official/low-rider",
      label: "Low Rider - War",
    },
    {
      url: "https://soundcloud.com/jean-knight-official/mr-big-stuff?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Mr. Big Stuff - Jean Knight",
    },
    {
      url: "https://soundcloud.com/thehollies/the-air-that-i-breathe-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Air That I Breathe - The Hollies",
    },
    {
      url: "https://soundcloud.com/brucespringsteen/born-to-run-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Born to Run - Bruce Springsteen",
    },
    {
      url: "https://soundcloud.com/simon-garfunkel-official/bridge-over-troubled-water-4?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bridge Over Troubled Water - Simon & Garfunkel",
    },
    {
      url: "https://soundcloud.com/althea-donna-official/uptown-top-ranking-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Uptown Top Ranking - Althea & Donna",
    },
    {
      url: "https://soundcloud.com/billy-paul-official/me-and-mrs-jones-6?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Me & Mrs. Jones - Billy Paul",
    },
    {
      url: "https://soundcloud.com/the-three-degrees-official/when-will-i-see-you-again-5?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "When Will I See Your Again - The Three Degrees",
    },
    {
      url: "https://soundcloud.com/harry-nilsson-official/without-you-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Without You - Harry Nilsson",
    },
    {
      url: "https://soundcloud.com/thelma-houston/dont-leave-me-this-way-single?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Don't Leave Me This Way - Thelma Houston",
    },
    {
      url: "https://soundcloud.com/carole-king-official/its-too-late?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "It's Too Late - Carole King",
    },
    {
      url: "https://soundcloud.com/carpenters-official/weve-only-just-begun-album?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "We've Only Just Begun - Carpenters",
    },
    {
      url: "https://soundcloud.com/kcthesunshineband/get-down-tonight-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Get Down Tonight - KC & the Sunshine Band",
    },
    {
      url: "https://soundcloud.com/theojays/back-stabbers-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Back Stabbers - The O'Jays",
    },
    {
      url: "https://soundcloud.com/patti-labelle-official/lady-marmalade-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lady Marmalade - LaBelle",
    },
    {
      url: "https://soundcloud.com/garynumanofficial/are-friends-electric-live-81?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Are 'Friends' Electric? - Gary Numan",
    },
    {
      url: "https://soundcloud.com/thestaplesingers/ill-take-you-there-single?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I'll Take You There - The Staple Singers",
    },
    {
      url: "https://soundcloud.com/minnie-riperton-official/lovin-you-1993-digital?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lovin' You - Minnie Riperton",
    },
    {
      url: "https://soundcloud.com/trex4/metal-guru-5?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Metal Guru - T. Rex",
    },
    {
      url: "https://soundcloud.com/marvin-gaye/whats-going-on-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "What's Going On - Marvin Gaye",
    },
    {
      url: "https://soundcloud.com/redbone-official/come-and-get-your-love-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Come and Get Your Love - Redbone",
    },
    {
      url: "https://soundcloud.com/bobdylan/tangled-up-in-blue?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Tangled Up In Blue - Bob Dylan",
    },
    {
      url: "https://soundcloud.com/barbra_streisand/the-way-we-were-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Way We Were - Barbra Streisand",
    },
    {
      url: "https://soundcloud.com/jimmycliffofficial/the-harder-they-come?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Harder They Come - Jimmy Cliff",
    },
    {
      url: "https://soundcloud.com/bostonpartymachine/brick-house-the-commodores?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Brick House - The Commodores",
    },
    {
      url: "https://soundcloud.com/sexpistolsofficial/god-save-the-queen-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "God Save The Queen - Sex Pistols",
    },
    {
      url: "https://soundcloud.com/jonimitchell/a-case-of-you?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "A Case of You - Joni Mitchell",
    },
    {
      url: "https://soundcloud.com/jamestaylorofficial/james-taylor-fire-and-rain?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Fire and Rain - James Taylor",
    },
    {
      url: "https://soundcloud.com/ohio-players/fire-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Fire - Ohio Players",
    },
    {
      url: "https://soundcloud.com/thevelvetundergroundofficial/rock-roll-loaded-original?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rock & Roll - The Velvet Underground",
    },
    {
      url: "https://soundcloud.com/kraftwerkofficial/trans-europe-express-2009?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Trans-Europe Express - Kraftwerk",
    },
    {
      url: "https://soundcloud.com/lindaronstadt/youre-no-good?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You're No Good - Linda Ronstadt",
    },
    {
      url: "https://soundcloud.com/pattismithmusic/gloria-remastered?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Gloria - Patti Smith",
    },
    {
      url: "https://soundcloud.com/sly-the-family-stone/family-affair-single-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Family Affair - Sly & The Family Stone",
    },
    {
      url: "https://soundcloud.com/george-harrison-official/my-sweet-lord?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "My Sweet Lord - George Harrison",
    },
    {
      url: "https://soundcloud.com/electric-light-orchestra/mr-blue-sky-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Mr. Blue Sky - Electric Light Orchestra",
    },
    {
      url: "https://soundcloud.com/john-travolta-official/youre-the-one-that-i-want-from?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You're the One That I Want - John Travolta, Olivia Newton-John",
    },
    {
      url: "https://soundcloud.com/mjimmortal/dont-stop-til-you-get-enough?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Don't Stop 'Til You Get Enough - Michael Jackson",
    },
    {
      url: "https://soundcloud.com/frankievallithefourseasons/december-1963-oh-what-a-night?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "December, 1963 - Frankie Valli & The Four Seasons",
    },
    {
      url: "https://soundcloud.com/carly-simon-official/youre-so-vain?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You're So Vain - Carly Simon",
    },
    {
      url: "https://soundcloud.com/fleetwoodmacofficial/go-your-own-way?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Go Your Own Way - Fleetwood Mac",
    },
    {
      url: "https://soundcloud.com/thesugarhillgang/rappers-delight-single-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rapper's Delight - The Sugarhill Gang",
    },
    {
      url: "https://soundcloud.com/elton-john/goodbye-yellow-brick-road-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Goodbye Yellow Brick Road - Elton John",
    },
    {
      url: "https://soundcloud.com/bill-withers-official/aint-no-sunshine?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Ain't No Sunshine - Bill Withers",
    },
    {
      url: "https://soundcloud.com/rodstewartofficial/maggie-may-remastered-album?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Maggie May - Rod Stewart",
    },
    {
      url: "https://soundcloud.com/earthwindandfire/september-from-the-best-of?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "September - Earth, Wind & Fire",
    },
    {
      url: "https://soundcloud.com/frankievallithefourseasons/grease-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Grease - Frankie Valli",
    },
    {
      url: "https://soundcloud.com/theojays/love-train-4?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Love Train - The O'Jays",
    },
    {
      url: "https://soundcloud.com/thehuescorporation/rock-the-boat-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rock the Boat - The Hues Corporation",
    },
    {
      url: "https://soundcloud.com/the-miracles-official/love-machine-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Love Machine - The Miracles",
    },
    {
      url: "https://soundcloud.com/donna-summer-official/hot-stuff-5?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hot Stuff - Donna Summer",
    },
    {
      url: "https://soundcloud.com/leosayerofficial/you-make-me-feel-like-5?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You Make Me Feel Like Dancing - Leo Sayer",
    },
    {
      url: "https://soundcloud.com/shocking-blue/venus-3?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Venus - Shocking Blue",
    },
    {
      url: "https://soundcloud.com/the-temptations/ball-of-confusion?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Ball of Confusion - The Temptations",
    },
    {
      url: "https://soundcloud.com/vanmorrisonofficial/moondance-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Moondance - Van Morrison",
    },
  ],
  "1980s": [
    {
      url: "https://soundcloud.com/a-ha/take-on-me",
      label: "Take On Me - A-Ha",
    },
    {
      url: "https://soundcloud.com/cultureclubofficial/do-you-really-want-to-hurt-4",
      label: "Do You Really Want To Hurt Me - Culture Club",
    },
    {
      url: "https://soundcloud.com/madonna/la-isla-bonita",
      label: "La Isla Bonita - Madonna",
    },
    {
      url: "https://soundcloud.com/luthervandrossmusic/never-too-much-5?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Never Too Much - Luther Vandross",
    },
    {
      url: "https://soundcloud.com/magb-unicamente/dire-straits-money-for-nothing-oldies-fm-903?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Money for Nothing - Dire Straits",
    },
    {
      url: "https://soundcloud.com/judaspriestofficial/youve-got-another-thing-comin?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You've Got Another Thing Coming - Judas Priest",
    },
    {
      url: "https://soundcloud.com/belinda-carlisle-official/heaven-is-a-place-on-earth",
      label: "Heaven is a Place On Earth - Belinda Carlisle",
    },
    {
      url: "https://soundcloud.com/cameo2/word-up-1",
      label: "Word Up - Cameo",
    },
    {
      url: "https://soundcloud.com/genesis4/turn-it-on-again-2007?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Turn It On Again - Genesis",
    },
    {
      url: "https://soundcloud.com/daryl-hall-john-oates/out-of-touch-remastered-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Out of Touch - Hall & Oates",
    },
    {
      url: "https://soundcloud.com/kim-carnes-official/bette-davis-eyes",
      label: "Bette Davis Eyes - Kim Carnes",
    },
    {
      url: "https://soundcloud.com/the-outfield/your-love-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Your Love - The Outfield",
    },
    {
      url: "https://soundcloud.com/mr-mister-official/broken-wings-4?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Broken Wings - Mr Mister",
    },
    {
      url: "https://soundcloud.com/john-waite-official/missing-you-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Missing You - John Waite",
    },
    {
      url: "https://soundcloud.com/shalamar-official/i-can-make-you-feel-good-2",
      label: "I Can Make You Feel Good - Shalamar",
    },
    {
      url: "https://soundcloud.com/phil-collins-official/in-the-air-tonight-2015",
      label: "In The Air Tonight - Phil Collins",
    },
    {
      url: "https://soundcloud.com/tina-turner-official/whats-love-got-to-do-with-it-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "What's Love Got to do With It - Tina Turner",
    },
    {
      url: "https://soundcloud.com/kate-bush-official/running-up-that-hill-a-deal",
      label: "Running Up That Hill - Kate Bush",
    },
    {
      url: "https://soundcloud.com/loverboy-official/working-for-the-weekend-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Working for the Weekend - Loverboy",
    },
    {
      url: "https://soundcloud.com/paulsimon/you-can-call-me-al",
      label: "You Can Call Me Al - Paul Simon",
    },
    {
      url: "https://soundcloud.com/theb52sofficial/love-shack-2",
      label: "Love Shack - The B-52's",
    },
    {
      url: "https://soundcloud.com/livingcolour/cult-of-personality-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Cult of Personality - Living Colour",
    },
    {
      url: "https://soundcloud.com/poison-official/nothin-but-a-good-time-2006?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Nothin' but A Good Time - Poison",
    },
    {
      url: "https://soundcloud.com/foreigner/hot-blooded?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hot Blooded - Foreigner",
    },
    {
      url: "https://soundcloud.com/queen-69312/one-vision-remastered-2011",
      label: "One Vision - Queen",
    },
    {
      url: "https://soundcloud.com/ozzy-osbourne-official/bark-at-the-moon?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bark at the Moon - Ozzy Osbourne",
    },
    {
      url: "https://soundcloud.com/toto-official/africa-7",
      label: "Africa - Toto",
    },
    {
      url: "https://soundcloud.com/cutting-crew-official/i-just-died-in-your-arms-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "(I Just) Died In Your Arms - Cutting Crew",
    },
    {
      url: "https://soundcloud.com/eurythmics-official/sweet-dreams-are-made-of-3",
      label: "Sweet Dreams (Are Made Of This) - Eurythmics",
    },
    {
      url: "https://soundcloud.com/mjimmortal/earth-song",
      label: "Earth Song - Michael Jackson",
    },
    {
      url: "https://soundcloud.com/foreigner/waiting-for-a-girl-like-you-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Waiting for a Girl Like You - Foreigner",
    },
    {
      url: "https://soundcloud.com/mickpuck/the-whole-of-the-moon",
      label: "The Whole of the Moon - The Waterboys",
    },
    {
      url: "https://soundcloud.com/tearsforfearsmusic/everybody-wants-to-rule-the-8",
      label: "Everybody Wants To Rule The World - Tears For Fears",
    },
    {
      url: "https://soundcloud.com/tpau-official/china-in-your-hand-single",
      label: "China In Your Hand - T'Pau",
    },
    {
      url: "https://soundcloud.com/prince/when-doves-cry-1",
      label: "When Doves Cry - Prince ",
    },
    {
      url: "https://soundcloud.com/thesmiths/the-queen-is-dead-2011?in=thesmiths%2Fsets%2Fthe-queen-is-dead-1",
      label: "The Queen Is Dead - The Smiths",
    },
    {
      url: "https://soundcloud.com/genesis4/mama-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Mama - Genesis",
    },
    {
      url: "https://soundcloud.com/fleetwoodmacofficial/little-lies",
      label: "Little Lies - Fleetwood Mac",
    },
    {
      url: "https://soundcloud.com/thesmiths/there-is-a-light-that-never-goes-out?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "There Is a Light That Never Goes Out - The Smiths",
    },
    {
      url: "https://soundcloud.com/janetjackson/nasty",
      label: "Nasty - Janet Jackson",
    },
    {
      url: "https://soundcloud.com/rick-springfield-official/jessies-girl-1",
      label: "Jessie's Girl - Rick Springfield",
    },
    {
      url: "https://soundcloud.com/prince/raspberry-beret",
      label: "Raspberry Beret - Prince",
    },
    {
      url: "https://soundcloud.com/wham-official/club-tropicana",
      label: "Club Tropicana - Wham!",
    },
    {
      url: "https://soundcloud.com/prince/kiss",
      label: "Kiss - Prince",
    },
    {
      url: "https://soundcloud.com/cultureclubofficial/karma-chameleon",
      label: "Karma Chameleon - Culture Club",
    },
    {
      url: "https://soundcloud.com/bananarama/love-in-the-first-degree",
      label: "Love in the first degree - Bananarama",
    },
    {
      url: "https://soundcloud.com/kate-bush-official/the-big-sky-2018-remaster?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Big Sky - Kate Bush",
    },
    {
      url: "https://soundcloud.com/whitneyhouston/i-wanna-dance-with-somebody-1",
      label: "I Wanna Dance with Somebody (Who Loves Me) - Whitney Houston",
    },
    {
      url: "https://soundcloud.com/bryan-adams-official/summer-of-69",
      label: "Summer of '69 - Bryan Adams",
    },
    {
      url: "https://soundcloud.com/earthwindandfire/lets-groove",
      label: "Let's Groove - Earth, Wind, & Fire",
    },
    {
      url: "https://soundcloud.com/davidbowieofficial/modern-love-2018-remastered",
      label: "Modern Love - David Bowie",
    },
    {
      url: "https://soundcloud.com/duranduran/hungry-like-the-wolf",
      label: "Hungry Like the Wolf - Duran Duran",
    },
    {
      url: "https://soundcloud.com/brucespringsteen/dancing-in-the-dark-5",
      label: "Dancing in the Dark - Bruce Springsteen",
    },
    {
      url: "https://soundcloud.com/cyndi-lauper-official/time-after-time",
      label: "Time After Time - Cyndi Lauper",
    },
    {
      url: "https://soundcloud.com/dianaross/chain-reaction-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Chain Reaction - Diana Ross",
    },
    {
      url: "https://soundcloud.com/duranduran/rio-2009-remastered-version",
      label: "Rio - Duran Duran",
    },
    {
      url: "https://soundcloud.com/guns-n-roses-official/sweet-child-o-mine",
      label: "Sweet Child O' Mine - Guns N' Roses",
    },
    {
      url: "https://soundcloud.com/queen-69312/another-one-bites-the-dust-4",
      label: "Another One Bites the Dust - Queen",
    },
    {
      url: "https://soundcloud.com/tiffany-official/i-think-were-alone-now-album",
      label: "I Think We're Alone Now - Tiffany",
    },
    {
      url: "https://soundcloud.com/kennyloggins/footloose-1",
      label: "Footloose - Kenny Loggins",
    },
    {
      url: "https://soundcloud.com/journey-official/dont-stop-believin-3",
      label: "Don't Stop Believin' - Journey",
    },
    {
      url: "https://soundcloud.com/bonjovi/livin-on-a-prayer-1",
      label: "Livin' On a Prayer - Bon Jovi",
    },
    {
      url: "https://soundcloud.com/fleetwoodmacofficial/everywhere",
      label: "Everywhere - Fleetwood Mac",
    },
    {
      url: "https://soundcloud.com/the-jam-official/town-called-malice",
      label: "Town Called Malice - The Jam",
    },
    {
      url: "https://soundcloud.com/dollyparton/9-to-5-11",
      label: "9 to 5 - Dolly Parton",
    },
    {
      url: "https://soundcloud.com/dexys-midnight-runners/come-on-eileen",
      label: "Come On Eileen - Dexy's Midnight Runners",
    },
    {
      url: "https://soundcloud.com/the-buggles/video-killed-the-radio-star",
      label: "Video Killed the Radio Star - The Buggles",
    },
    {
      url: "https://soundcloud.com/erasureofficial/a-little-respect-1",
      label: "A Little Respect - Erasure",
    },
    {
      url: "https://soundcloud.com/thehumanleagueofficial/dont-you-want-me-2002-remaster",
      label: "Don't You Want Me - The Human League",
    },
    {
      url: "https://soundcloud.com/lionelrichieofficial/all-night-long-all-night",
      label: "All Night Long - Lionel Richie",
    },
    {
      url: "https://soundcloud.com/prince/1999-1",
      label: "1999 - Prince",
    },
    {
      url: "https://soundcloud.com/bonjovi/you-give-love-a-bad-name-1",
      label: "You Give Love a Bad Name - Bon Jovi",
    },
    {
      url: "https://soundcloud.com/madonna/like-a-prayer-album-version",
      label: "Like a Prayer - Madonna",
    },
    {
      url: "https://soundcloud.com/mjimmortal/man-in-the-mirror",
      label: "Man In the Mirror - Michael Jackson",
    },
    {
      url: "https://soundcloud.com/katrina-and-the-waves/walking-on-sunshine-1",
      label: "Walking On Sunshine - Katrina and the Waves",
    },
    {
      url: "https://soundcloud.com/prince/purple-rain",
      label: "Purple Rain - Prince & The Revolution",
    },
    {
      url: "https://soundcloud.com/starship-official/nothings-gonna-stop-us-now-5",
      label: "Nothing's Gonna Stop Us Now - Starship",
    },
    {
      url: "https://soundcloud.com/neworderofficial/blue-monday-2016-remaster",
      label: "Blue Monday - New Order",
    },
    {
      url: "https://soundcloud.com/phil-collins-official/you-cant-hurry-love-2016",
      label: "You Can't Hurry Love - Phil Collins",
    },
    {
      url: "https://soundcloud.com/wham-official/wake-me-up-before-you-go-go",
      label: "Wake Me Up Before You Go-Go - Wham!",
    },
    {
      url: "https://soundcloud.com/queen-69312/under-pressure-1994-remastered",
      label: "Under Pressure - Queen",
    },
    {
      url: "https://soundcloud.com/ub40-official/red-red-wine",
      label: "Red Red Wine - UB40",
    },
    {
      url: "https://soundcloud.com/cyndi-lauper-official/girls-just-want-to-have-fun-8",
      label: "Girls Just Want to Have Fun - Cyndi Lauper",
    },
    {
      url: "https://soundcloud.com/direstraits/walk-of-life-remastered-1996",
      label: "Walk Of Life - Dire Straits",
    },
    {
      url: "https://soundcloud.com/bonnie-tyler-music/total-eclipse-of-the-heart-2",
      label: "Total Eclipse of the Heart - Bonnie Tyler",
    },
    {
      url: "https://soundcloud.com/the-police-official/every-breath-you-take-2003",
      label: "Every Breath You Take - The Police",
    },
    {
      url: "https://soundcloud.com/daryl-hall-john-oates/you-make-my-dreams-remastered",
      label: "You Make My Dreams (Come True) - Daryl Hall & John Oates",
    },
    {
      url: "https://soundcloud.com/mjimmortal/beat-it-single-version",
      label: "Beat It - Michael Jackson",
    },
    {
      url: "https://soundcloud.com/stevienicks/edge-of-seventeen",
      label: "Edge of Seventeen - Stevie Nicks",
    },
    {
      url: "https://soundcloud.com/rickjamesofficial/super-freak",
      label: "Super Freak - Rick James",
    },
    {
      url: "https://soundcloud.com/don-henley-official/the-boys-of-summer",
      label: "The Boys of Summer - Don Henley",
    },
    {
      url: "https://soundcloud.com/billyjoel/uptown-girl",
      label: "Uptown Girl - Billy Joel",
    },
    {
      url: "https://soundcloud.com/whitneyhouston/how-will-i-know",
      label: "How Will I Know - Whitney Houston",
    },
    {
      url: "https://soundcloud.com/madnessofficial/our-house-2",
      label: "Our House - Madness",
    },
    {
      url: "https://soundcloud.com/bronski-beat/smalltown-boy",
      label: "Smalltown Boy - Bronksi Beat",
    },
    {
      url: "https://soundcloud.com/petshopboysofficial/west-end-girls-2018-remastered",
      label: "West End Girls - Pet Shop Boys",
    },
    {
      url: "https://soundcloud.com/u2/with-or-without-you-1",
      label: "With or Without You - U2",
    },
    {
      url: "https://soundcloud.com/survivor-official/eye-of-the-tiger-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Eye of the Tiger - Survivor",
    },
    {
      url: "https://soundcloud.com/frankie-goes-to-hollywood-official/relax?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Relax - Frankie Goes to Hollywood",
    },
    {
      url: "https://soundcloud.com/womackwomack/teardrops?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Teardrops - Womack & Womack",
    },
    {
      url: "https://soundcloud.com/madonna/material-girl?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Material Girl - Madonna",
    },
    {
      url: "https://soundcloud.com/depeche_mode/just-cant-get-enough-2006?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Just Can't Get Enough - Depeche Mode",
    },
    {
      url: "https://soundcloud.com/laurabranigan/gloria?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Gloria - Laura Branigan",
    },
    {
      url: "https://soundcloud.com/acdcofficial/back-in-black?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Back In Black - AC/DC",
    },
    {
      url: "https://soundcloud.com/blackboxhouse/ride-on-time?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Ride On Time - Black Box",
    },
    {
      url: "https://soundcloud.com/joanjettandtheblackhearts/i-love-rock-n-roll-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Love Rock 'N Roll - Joan Jett & The Blackhearts",
    },
    {
      url: "https://soundcloud.com/rick-astley-official/never-gonna-give-you-up-4?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Never Gonna Give You Up - Rick Astley",
    },
    {
      url: "https://soundcloud.com/dead-or-alive-official/you-spin-me-round-like-a-3?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You Spin Me Round (Like A Record) - Dead or Alive",
    },
    {
      url: "https://soundcloud.com/marvin-gaye/sexual-healing?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sexual Healing - Marvin Gaye",
    },
    {
      url: "https://soundcloud.com/heartofficial/alone?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Alone - Heart",
    },
    {
      url: "https://soundcloud.com/daryl-hall-john-oates/maneater-remastered-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Maneater - Daryl Hall & John Oates",
    },
    {
      url: "https://soundcloud.com/blondie/call-me-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Call Me - Blondie",
    },
    {
      url: "https://soundcloud.com/inxsofficial/need-you-tonight-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Need You Tonight - INXS",
    },
    {
      url: "https://soundcloud.com/irene-cara-official/flashdance-what-a-feeling-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Flashdance...What a Feeling - Irene Cara",
    },
    {
      url: "https://soundcloud.com/dianaross/im-coming-out?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I'm Coming Out - Diana Ross",
    },
    {
      url: "https://soundcloud.com/vanhalenofficial/jump-2015-remastered-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Jump - Van Halen",
    },
    {
      url: "https://soundcloud.com/run-dmcofficial/walk-this-way-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Walk This Way - Run-DMC",
    },
    {
      url: "https://soundcloud.com/petshopboysofficial/its-a-sin?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "It's a Sin - Pet Shop Boys",
    },
    {
      url: "https://soundcloud.com/the-pointer-sisters/im-so-excited-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I'm So Excited - The Pointer Sisters",
    },
    {
      url: "https://soundcloud.com/madonna/into-the-groove?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Into the Groove - Madonna",
    },
    {
      url: "https://soundcloud.com/abba-official/super-trouper-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Super Trouper - ABBA",
    },
    {
      url: "https://soundcloud.com/foreigner/i-want-to-know-what-love-is?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Want to Know What Love Is - Foreigner",
    },
    {
      url: "https://soundcloud.com/brucehornsbytherange/the-way-it-is?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Way It Is - Bruce Hornsby & The Range",
    },
    {
      url: "https://soundcloud.com/billyocean-official/when-the-going-gets-tough-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "When the Going Gets Tough - Billy Ocean",
    },
    {
      url: "https://soundcloud.com/madonna/holiday-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Holiday - Madonna",
    },
    {
      url: "https://soundcloud.com/joy-division-3/love-will-tear-us-apart?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Love Will Tear Us Apart - Joy Division",
    },
    {
      url: "https://soundcloud.com/robert-palmer-official/addicted-to-love-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Addicted to Love - Robert Palmer",
    },
    {
      url: "https://soundcloud.com/crowded-house/dont-dream-its-over?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Don't Dream It's Over - Crowded House",
    },
    {
      url: "https://soundcloud.com/davidbowieofficial/lets-dance-single-version-2014?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Let's Dance - David Bowie",
    },
    {
      url: "https://soundcloud.com/irene-cara-official/fame-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Fame - Irene Cara",
    },
    {
      url: "https://soundcloud.com/acdcofficial/you-shook-me-all-night-long-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You Shook Me All Night Long - AC/DC",
    },
    {
      url: "https://soundcloud.com/spandau_ballet/true-12-mix?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "True - Spandau Ballet",
    },
    {
      url: "https://soundcloud.com/phil-collins-official/against-all-odds-take-a-look-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Against All Odds - Phil Collins",
    },
    {
      url: "https://soundcloud.com/def-leppard-official/pour-some-sugar-on-me-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Pour Some Sugar On Me - Def Leppard",
    },
    {
      url: "https://soundcloud.com/mjimmortal/bad-2012-remaster?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bad - Michael Jackson",
    },
    {
      url: "https://soundcloud.com/bonjovi/always-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Always - Bon Jovi",
    },
    {
      url: "https://soundcloud.com/salt-n-pepa-official/push-it-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Push It - Salt-N-Pepa",
    },
    {
      url: "https://soundcloud.com/frankie-goes-to-hollywood-official/the-power-of-love?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Power of Love - Frankie Goes to Hollywood",
    },
    {
      url: "https://soundcloud.com/pat-benatar-official/love-is-a-battlefield-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Love Is a Battlefield - Pat Benatar",
    },
    {
      url: "https://soundcloud.com/the-bangles-official/walk-like-an-egyptian-3?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Walk Like an Egyptian - The Bangles",
    },
    {
      url: "https://soundcloud.com/brucespringsteen/born-in-the-u-s-a?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Born In the U.S.A. - Bruce Springsteen",
    },
    {
      url: "https://soundcloud.com/dianaross/upside-down?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Upside Down - Diana Ross",
    },
    {
      url: "https://soundcloud.com/peter-gabriel-official/sledgehammer-2012-remastered?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sledgehammer - Peter Gabriel",
    },
    {
      url: "https://soundcloud.com/madonna/like-a-virgin?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Like a Virgin - Madonna",
    },
    {
      url: "https://soundcloud.com/officialpinkfloyd/another-brick-in-the-wall-pt-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Another Brick in the Wall, Pt. 2 - Pink Floyd",
    },
    {
      url: "https://soundcloud.com/the-police-official/dont-stand-so-close-to-me-2003?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Don't Stand So Close to Me - The Police",
    },
  ],
  "1990s": [
    {
      url: "https://soundcloud.com/vanhalenofficial/van-halen-jump",
      label: "Jump - Van Halen",
    },
    {
      url: "https://soundcloud.com/soundgarden/the-day-i-tried-to-live-album",
      label: "The Day I Tried To Live - Soundgarden",
    },
    {
      url: "https://soundcloud.com/red-hot-chili-peppers-official/californication",
      label: "Californication - Red Hot Chili Peppers",
    },
    {
      url: "https://soundcloud.com/natalie-imbruglia/big-mistake",
      label: "Big Mistake - Natalie Imbruglia",
    },
    {
      url: "https://soundcloud.com/guns-n-roses-official/paradise-city",
      label: "Paradise City - Guns N' Roses",
    },
    {
      url: "https://soundcloud.com/nwaofficial/express-yourself-1",
      label: "Express Yourself - N.W.A",
    },
    {
      url: "https://soundcloud.com/mjimmortal/billie-jean-single-version",
      label: "Billie Jean - Michael Jackson",
    },
    {
      url: "https://soundcloud.com/tompettymusic/free-fallin-2",
      label: "Free Fallin' - Tom Petty",
    },
    {
      url: "https://soundcloud.com/bloodhound-gang/the-bad-touch-album-version",
      label: "The Bad Touch - Bloodhound gang",
    },
    {
      url: "https://soundcloud.com/whitetown/04-your-woman",
      label: "Your Woman - White Town",
    },
    {
      url: "https://soundcloud.com/texastheband/when-we-are-together-1",
      label: "When We Are Together - Texas",
    },
    {
      url: "https://soundcloud.com/hanson-official/mmmbop-2",
      label: "MMMBop - Hanson",
    },
    {
      url: "https://soundcloud.com/supergrass-official/alright-2015-remastered",
      label: "Alright - Supergrass",
    },
    {
      url: "https://soundcloud.com/snap27105/rhythm-is-a-dancer-2",
      label: "Rhythm is a Dancer - Snap!",
    },
    {
      url: "https://soundcloud.com/mad_hatter-886427812/the-verve-bittersweet-symphony?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bittersweet Synphony - The Verve",
    },
    {
      url: "https://soundcloud.com/kornofficial/freak-on-a-leash-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Freak On a Leash - KoRn",
    },
    {
      url: "https://soundcloud.com/alanismorissette/ironic-1",
      label: "Ironic - Alanis Morissette",
    },
    {
      url: "https://soundcloud.com/go-west-official/the-king-of-wishful-thinking?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The King of Wishful Thinking - Go West",
    },
    {
      url: "https://soundcloud.com/britneyspears/baby-one-more-time",
      label: "Baby One More Time - Britney Spears",
    },
    {
      url: "https://soundcloud.com/spice-girls-official/wannabe-radio-edit?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Wannabe - Spice Girls",
    },
    {
      url: "https://soundcloud.com/blurofficial/song-2",
      label: "Song 2 - Blur",
    },
    {
      url: "https://soundcloud.com/thegoogoodolls/iris-1",
      label: "Iris - Goo Goo Dolls",
    },
    {
      url: "https://soundcloud.com/public-enemy-music/bring-the-noise",
      label: "Bring The Noise - Public Enemy",
    },
    {
      url: "https://soundcloud.com/crowded-house/weather-with-you",
      label: "Whether With You - Crowded House",
    },
    {
      url: "https://soundcloud.com/greenday/when-i-come-around",
      label: "When I Come Around - Green Day",
    },
    {
      url: "https://soundcloud.com/theprodigy/out-of-space-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Out Of Space - Prodigy",
    },
    {
      url: "https://soundcloud.com/shadx777/roy-davis-jr-gabriel-ft-preven-everett-live-garage-version",
      label: "Gabriel - Roy Davis Jr & Peven Everett",
    },
    {
      url: "https://soundcloud.com/m-people-official/search-for-the-hero-2",
      label: "Search For The Hero - M People",
    },
    {
      url: "https://soundcloud.com/bryan-adams-official/when-youre-gone",
      label: "When You're Gone - Bryan Adams (feat. Melanie C)",
    },
    {
      url: "https://soundcloud.com/wham-official/club-tropicana",
      label: "Club Tropicana - Wham!",
    },
    {
      url: "https://soundcloud.com/planned_obsolescence/loser-album-version",
      label: "Loser - Beck",
    },
    {
      url: "https://soundcloud.com/hiphop-classics1/digable-planets-where-im-from",
      label: "Where I'm From - Digable Planets",
    },
    {
      url: "https://soundcloud.com/beastieboys/shake-your-rump-2009-digital",
      label: "Shake Your Rump - Beastie Boys",
    },
    {
      url: "https://soundcloud.com/tlcofficial/waterfalls-3",
      label: "Waterfalls - TLC",
    },
    {
      url: "https://soundcloud.com/fatboyslim/09-praise-you",
      label: "Praise You - Fatboy Slim",
    },
    {
      url: "https://soundcloud.com/christinaaguilera/genie-in-a-bottle",
      label: "Genie In A Bottle - Christina Aguilera",
    },
    {
      url: "https://soundcloud.com/thecardigans/erase-rewind",
      label: "Erase / Rewind - The Cardigans",
    },
    {
      url: "https://soundcloud.com/warrantofficial/cherry-pie-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Cherry Pie - Warrant",
    },
    {
      url: "https://soundcloud.com/official-billy-idol/rebel-yell",
      label: "Rebel Yell - Billy Idol",
    },
    {
      url: "https://soundcloud.com/rageagainstthemachineofficial/testify-album-version",
      label: "Testify - Rage Against The Machine",
    },
    {
      url: "https://soundcloud.com/annie-lennox-official/walking-on-broken-glass",
      label: "Walking On Broken Glass - Annie Lennox",
    },
    {
      url: "https://soundcloud.com/s-club-7-official/s-club-party",
      label: "S Club Party - S Club 7",
    },
    {
      url: "https://soundcloud.com/oldirtybastardofficial/shimmy-shimmy-ya-1",
      label: "Shimmy Shimmy Ya - Ol' Dirty Bastard",
    },
    {
      url: "https://soundcloud.com/run-dmcofficial/rock-box",
      label: "Rock Box - RUN-DMC",
    },
    {
      url: "https://soundcloud.com/lit-official/my-own-worst-enemy?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "My Own Worst Enemy - Lit",
    },
    {
      url: "https://soundcloud.com/jamiroquai/canned-heat-1",
      label: "Canned Heat - Jamiroquai",
    },
    {
      url: "https://soundcloud.com/thebackstreetboys/everybody-backstreets-back-1",
      label: "Everybody (Backstreet's Back) - Backstreet Boys",
    },
    {
      url: "https://soundcloud.com/celinedionofficial/my-heart-will-go-on-from-the",
      label: "My Heart Will Go On - Celine Dion",
    },
    {
      url: "https://soundcloud.com/hiphop-classics1/queen-latifah-unity-1995",
      label: "U.N.I.T.Y - Queen Latifah",
    },
    {
      url: "https://soundcloud.com/len-official/steal-my-sunshine",
      label: "Steal My Sunshine - LEN",
    },
    {
      url: "https://soundcloud.com/bwitched-official/cest-la-vie",
      label: "C'est La Vie - B*Witched",
    },
    {
      url: "https://soundcloud.com/darude/sandstorm-radio-edit",
      label: "Sandstorm - Darude",
    },
    {
      url: "https://soundcloud.com/rednex-se/cotton-eye-joe-1",
      label: "Cotton Eye Joe - Rednex",
    },
    {
      url: "https://soundcloud.com/m-people-official/moving-on-up",
      label: "Moving on Up - M People",
    },
    {
      url: "https://soundcloud.com/run-dmcofficial/its-like-that-2",
      label: "It's Like That - RUN-DMC",
    },
    {
      url: "https://soundcloud.com/scatman-john-official/scatman-ski-ba-bop-ba-dop-5",
      label: "Scatman (ski-ba-bop-ba-dop-bop) - Scatman John",
    },
    {
      url: "https://soundcloud.com/amygrantofficial/baby-baby-remastered-2007",
      label: "Baby, Baby - Amy Grant",
    },
    {
      url: "https://soundcloud.com/stereo-mcs/connected",
      label: "Connected - Stereo MC's",
    },
    {
      url: "https://soundcloud.com/def-leppard-official/lets-get-rocked",
      label: "Let's Get Rocked - Deaf Leppard",
    },
    {
      url: "https://soundcloud.com/crazy-town-official/butterfly-1",
      label: "Butterfly - Crazy Town",
    },
    {
      url: "https://soundcloud.com/charles-eddie-official/would-i-lie-to-you",
      label: "Would I Lie To You? - Charles & Eddie",
    },
    {
      url: "https://soundcloud.com/barenaked-ladies/one-week",
      label: "One Week - Barenaked Ladies",
    },
    {
      url: "https://soundcloud.com/bonjovi/keep-the-faith-album-version",
      label: "Keep The Faith - Bon Jovi",
    },
    {
      url: "https://soundcloud.com/smash-mouth-official/all-star-album-version",
      label: "All Star - Smash Mouth",
    },
    {
      url: "https://soundcloud.com/official-simply-red/something-got-me-started",
      label: "Something Got Me Started - Simply Red",
    },
    { url: "https://soundcloud.com/seal/crazy", label: "Crazy - Seal" },
    {
      url: "https://soundcloud.com/oasisofficial/wonderwall-2",
      label: "Wonderwall - Oasis",
    },
    {
      url: "https://soundcloud.com/tlcofficial/no-scrubs-2",
      label: "No Scrubs - TLC",
    },
    {
      url: "https://soundcloud.com/thebackstreetboys/i-want-it-that-way",
      label: "I Want It That Way - Backstreet Boys",
    },
    {
      url: "https://soundcloud.com/coolio/gangstas-paradise",
      label: "Gangsta's Paradise - Coolio",
    },
    {
      url: "https://soundcloud.com/natalie-imbruglia/torn",
      label: "Torn - Natalie Imbruglia",
    },
    {
      url: "https://soundcloud.com/h2h1133/cher-cher-believe-original-bek",
      label: "Believe - Cher",
    },
    {
      url: "https://soundcloud.com/peter-andre-official/mysterious-girl-radio-edit",
      label: "Mysterious Girl - Peter Andre",
    },
    {
      url: "https://soundcloud.com/redbeatmusic/boom-boom-boom-boom-vengaboys",
      label: "Boom, Boom, Boom, Boom!! - Vengaboys",
    },
    {
      url: "https://soundcloud.com/gabrielle-82009/dreams-album-version",
      label: "Dreams - Gabrielle",
    },
    {
      url: "https://soundcloud.com/diddy8/ill-be-missing-you-feat",
      label: "I'll Be Missing You - Puff Daddy, Faith Evans & 112",
    },
    {
      url: "https://soundcloud.com/five98613/keep-on-movin",
      label: "Keep On Movin' - Five",
    },
    {
      url: "https://soundcloud.com/all-saints-official/never-ever",
      label: "Never Ever - All Saints",
    },
    {
      url: "https://soundcloud.com/deep-blue-something/breakfast-at-tiffanys",
      label: "Breakfast At Tiffany's - Deep Blue Something",
    },
    {
      url: "https://soundcloud.com/blackstreet-official/no-diggity-album-version",
      label: "No Diggity - Blackstreet",
    },
    {
      url: "https://soundcloud.com/destinys-child-official/say-my-name",
      label: "Say My Name - Destiny's Child",
    },
    {
      url: "https://soundcloud.com/aceofbaseofficial/all-that-she-wants",
      label: "All That She Wants - Ace of Base",
    },
    {
      url: "https://soundcloud.com/rem-official/losing-my-religion-3",
      label: "Losing My Religion - R.E.M.",
    },
    {
      url: "https://soundcloud.com/cornershop/brimful-of-asha",
      label: "Brimful of Asha - Cornershop",
    },
    {
      url: "https://soundcloud.com/underworld/born-slippy-nuxx-radio-edit",
      label: "Born Slippy - Underworld",
    },
    {
      url: "https://soundcloud.com/nodoubt/dont-speak",
      label: "Don't Speak - No Doubt",
    },
    {
      url: "https://soundcloud.com/will-smith-official/men-in-black",
      label: "Men In Black - Will Smith",
    },
    {
      url: "https://soundcloud.com/corona-official/the-rhythm-of-the-night",
      label: "The Rhythm of the Night - Corona",
    },
    {
      url: "https://soundcloud.com/markmorrisonmusic/return-of-the-mack-c-j-street",
      label: "Return of the Mack - Mark Morrison",
    },
    {
      url: "https://soundcloud.com/oasisofficial/dont-look-back-in-anger-1",
      label: "Don't Look Back in Anger - Oasis",
    },
    {
      url: "https://soundcloud.com/savage-garden-official/truly-madly-deeply-1",
      label: "Truly Madly Deeply - Savage Garden",
    },
    {
      url: "https://soundcloud.com/nirvana/smells-like-teen-spirit-1",
      label: "Smells Like Teen Spirit - Nirvana",
    },
    {
      url: "https://soundcloud.com/will-smith-official/gettin-jiggy-wit-it-1",
      label: "Gettin' Jiggy Wit It - Will Smith",
    },
    {
      url: "https://soundcloud.com/vanilla-ice-official/ice-ice-baby",
      label: "Ice Ice Baby - Vanilla Ice",
    },
    {
      url: "https://soundcloud.com/cafedelantaarn/house-of-pain-jump-around",
      label: "Jump Around - House of Pain",
    },
    {
      url: "https://soundcloud.com/liltunechi93/notorious-big-big-poppa",
      label: "Big Poppa - The Notorious B.I.G.",
    },
    {
      url: "https://soundcloud.com/fugees/killing-me-softly-with-his-3",
      label: "Killing Me Softly With His Song - Fugees",
    },
    {
      url: "https://soundcloud.com/meatloafofficial/id-do-anything-for-love-but-3",
      label: "I'd Do Anything For Love - Meat Loaf",
    },
    {
      url: "https://soundcloud.com/culture-beat-official/mr-vain",
      label: "Mr. Vain - Culture Beat",
    },
    {
      url: "https://soundcloud.com/boyz-ii-men-official/end-of-the-road-album-version",
      label: "End of the Road - Boyz II Men",
    },
    {
      url: "https://soundcloud.com/dream-89510/things-can-only-get-better?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Things Can Only Get Better - D:Ream",
    },
    {
      url: "https://soundcloud.com/blink-182/all-the-small-things?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "All The Small Things - blink-182",
    },
    {
      url: "https://soundcloud.com/scream-and-shout/klaas-meets-haddaway-what-is-love-2k9-klaas-radio-edit?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "What Is Love - Haddaway",
    },
    {
      url: "https://soundcloud.com/robbiewilliams/angels-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Angels - Robbie Williams",
    },
    {
      url: "https://soundcloud.com/seal/kiss-from-a-rose-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Kiss from a Rose - Seal",
    },
    {
      url: "https://soundcloud.com/red-hot-chili-peppers-official/under-the-bridge-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Under the Bridge - Red Hot Chili Peppers",
    },
    {
      url: "https://soundcloud.com/ricky-martin-official/livin-la-vida-loca?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Livin' la Vida Loca - Ricky Martin",
    },
    {
      url: "https://soundcloud.com/ronan-keating-official/when-you-say-nothing-at-all-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "When You Say Nothing At All - Ronan Keating",
    },
    {
      url: "https://soundcloud.com/greenday/basket-case?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Basket Case - Green Day",
    },
    {
      url: "https://soundcloud.com/s-club-7-official/reach?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Reach - S Club 7",
    },
    {
      url: "https://soundcloud.com/los-del-rio/macarena-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Macarena - Los del Río",
    },
    {
      url: "https://soundcloud.com/pulp-official/common-people-full-length?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Common People - Pulp",
    },
    {
      url: "https://soundcloud.com/faithless/insomnia-original-mix-radio?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Insomnia - Faithless",
    },
    {
      url: "https://soundcloud.com/inner-circle-official/sweat-a-la-la-la-la-long-3?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sweat - Inner Circle",
    },
    {
      url: "https://soundcloud.com/desree-official/you-gotta-be-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You Gotta Be - Des'ree",
    },
    {
      url: "https://soundcloud.com/sixpence-none-the-richer-official/kiss-me-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Kiss Me - Sixpence None the Richer",
    },
    {
      url: "https://soundcloud.com/aqua-official/doctor-jones?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Doctor Jones - Aqua",
    },
    {
      url: "https://soundcloud.com/ginuwine-official/pony?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Pony - Ginuwine",
    },
    {
      url: "https://soundcloud.com/stardust47685/stardust-music-sounds-better-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Music Sounds Better With You - Stardust",
    },
    {
      url: "https://soundcloud.com/thecardigans/lovefool-radio-edit?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lovefool - The Cardigans",
    },
    {
      url: "https://soundcloud.com/wilsonphillips/hold-on-single-edit-2000?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hold On - Wilson Phillips",
    },
    {
      url: "https://soundcloud.com/spice-girls-official/say-youll-be-there-single-mix?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Say You'll Be There - Spice Girls",
    },
    {
      url: "https://soundcloud.com/take-that-official/back-for-good?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Back for Good - Take That",
    },
    {
      url: "https://soundcloud.com/new-radicals/you-get-what-you-give?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You Get What You Give - New Radicals",
    },
    {
      url: "https://soundcloud.com/davidgray/babylon?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Babylon - David Gray",
    },
    {
      url: "https://soundcloud.com/ini-kamoze-official/here-comes-the-hotstepper?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Here Comes the Hotstepper - Ini Kamoze",
    },
    {
      url: "https://soundcloud.com/bonjovi/always-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Always - Bon Jovi",
    },
    {
      url: "https://soundcloud.com/steps20027/tragedy-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Tragedy - Steps",
    },
    {
      url: "https://soundcloud.com/westlife-official/flying-without-wings?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Flying Without Wings - Westlife",
    },
    {
      url: "https://soundcloud.com/notoriousbig/mo-money-mo-problems-feat-3?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Mo Money Mo Problems - The Notorious B.I.G.",
    },
    {
      url: "https://soundcloud.com/sineadoconnor/nothing-compares-2-u-2009?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Nothing Compares 2 U - Sinéad O'Connor",
    },
    {
      url: "https://soundcloud.com/bryan-adams-official/everything-i-do-i-do-it-for-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "(Everything I Do) I Do It for You - Bryan Adams",
    },
    {
      url: "https://soundcloud.com/sir-mix-a-lot-official/baby-got-back-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Baby Got Back - Sir Mix-A-Lot",
    },
    {
      url: "https://soundcloud.com/the-cranberries/dreams?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Dreams - The Cranberries",
    },
    {
      url: "https://soundcloud.com/lauryn-hill-official/doo-wop-that-thing-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Doo Wop - Lauryn Hill",
    },
    {
      url: "https://soundcloud.com/s-club-7-official/bring-it-all-back?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bring It All Back - S Club 7",
    },
    {
      url: "https://soundcloud.com/celinedionofficial/think-twice?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Think Twice - Céline Dion",
    },
    {
      url: "https://soundcloud.com/real-mccoy-official/another-night?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Another Night - Real McCoy",
    },
    {
      url: "https://soundcloud.com/mariahcarey/fantasy?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Fantasy - Mariah Carey",
    },
    {
      url: "https://soundcloud.com/all-4-one/i-swear?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Swear - All-4-One",
    },
    {
      url: "https://soundcloud.com/envogueofficial/en-vogue-dont-let-go-love?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Don't Let Go (Love) - En Vogue",
    },
    {
      url: "https://soundcloud.com/offspring/pretty-fly-for-a-white-guy-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Pretty Fly (For a White Guy) - The Offspring",
    },
    {
      url: "https://soundcloud.com/spin-doctors/two-princes?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Two Princes - Spin Doctors",
    },
    {
      url: "https://soundcloud.com/chumbawamba-official/tubthumping?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Tubthumping - Chumbawamba",
    },
    {
      url: "https://soundcloud.com/aceofbaseofficial/the-sign?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Sign - Ace of Base",
    },
    {
      url: "https://soundcloud.com/jamiroquai/virtual-insanity-remastered?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Virtual Insanity - Jamiroquai",
    },
  ],
  "2000s": [
    {
      url: "https://soundcloud.com/macedomargaiza/sets/kylie-minogue-in-your-eyes",
      label: "In Your Eyes - Kylie Minogue",
    },
    {
      url: "https://soundcloud.com/kanyewest/love-lockdown",
      label: "Love Lockdown - Kanye West",
    },
    {
      url: "https://soundcloud.com/gorillaz/dirty-harry",
      label: "Dirty Harry - Gorillaz",
    },
    {
      url: "https://soundcloud.com/gorillaz/feel-good-inc-album-version",
      label: "Feel Good Inc. - Gorillaz",
    },
    {
      url: "https://soundcloud.com/puddle-of-mudd-official/blurry?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Blurry - Puddle of Mudd",
    },
    {
      url: "https://soundcloud.com/lazlobaneofficial/superman-album-version",
      label: "Superman - Lazlo Bane",
    },
    {
      url: "https://soundcloud.com/nellyfurtado/im-like-a-bird-album-version",
      label: "I'm Like A Bird - Nelly Furtado",
    },
    {
      url: "https://soundcloud.com/slipknot/before-i-forget?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Before I Forget - Slipknot",
    },
    {
      url: "https://soundcloud.com/sophie-ellis-bextor-official/murder-on-the-dancefloor-album",
      label: "Murder On The Dancefloor - Sophie Ellis-Bextor",
    },
    {
      url: "https://soundcloud.com/linkin_park/in-the-end",
      label: "In the End - Linkin Park",
    },
    {
      url: "https://soundcloud.com/falloutboy/a-little-less-sixteen-candles",
      label:
        'A Little Less Sixteen Candles, A Little More "Touch Me" - Fall Out Boy',
    },
    {
      url: "https://soundcloud.com/qotsa/no-one-knows-album-version",
      label: "No One Knows - Queens of the Stone Age",
    },
    { url: "https://soundcloud.com/sum41/fat-lip", label: "Fat Lip - Sum 41" },
    {
      url: "https://soundcloud.com/bowling-for-soup/girl-all-the-bad-guys-want?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Girl All the Bad Guys Want - Bowling For Soup",
    },
    {
      url: "https://soundcloud.com/marymarymusic/shackles-praise-you-single",
      label: "Shackles (Praise You) - Mary Mary",
    },
    {
      url: "https://soundcloud.com/peterbjornjohn/young-folks",
      label: "Young Folks - Peter Bjorn and John",
    },
    {
      url: "https://soundcloud.com/system-of-a-down-official/chop-suey?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Chop Suey! - System of a Down",
    },
    {
      url: "https://soundcloud.com/lordi-official/hardrock-hallelujah?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hard Rock Hallelujah - Lordi",
    },
    {
      url: "https://soundcloud.com/fountains-of-wayne/stacys-mom?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Stacey's Mom - Fountains of Wayne",
    },
    {
      url: "https://soundcloud.com/usher-raymond-music/love-in-this-club?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Love in This Club - Usher",
    },
    {
      url: "https://soundcloud.com/girls-aloud-official/sound-of-the-underground",
      label: "Sound of the Underground - Girls Aloud",
    },
    { url: "https://soundcloud.com/beyonce/halo", label: "Halo - Beyoncé" },
    {
      url: "https://soundcloud.com/rogersanchez/another-chance-radio-edit",
      label: "Another Chance - Roger Sanchez",
    },
    {
      url: "https://soundcloud.com/mix-93-3-all-the-mix/tatu-all-the-things-she-said-2",
      label: "All The Things She Said - Tatu",
    },
    {
      url: "https://soundcloud.com/sugababes/push-the-button",
      label: "Push the Button - Sugababes",
    },
    {
      url: "https://soundcloud.com/dido/thank-you-2",
      label: "Thank You - Dido",
    },
    {
      url: "https://soundcloud.com/linkin_park/what-ive-done?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "What I've Done - Linkin Park",
    },
    {
      url: "https://soundcloud.com/busted-official/thunderbirds-are-go",
      label: "Thunderbirds Are Go - Busted",
    },
    {
      url: "https://soundcloud.com/arcticmonkeys/from-the-ritz-to-the-rubble?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "From the Ritz to the Rubble - Arctic Monkeys",
    },
    {
      url: "https://soundcloud.com/kanyewest/flashing-lights-1",
      label: "Flashing Lights - Kanye West",
    },
    {
      url: "https://soundcloud.com/stardust47685/stardust-music-sounds-better-1",
      label: "Music Sounds Better With You - Stardust",
    },
    {
      url: "https://soundcloud.com/ladygaga/just-dance?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Just Dance - Lady Gaga",
    },
    {
      url: "https://soundcloud.com/my-chemical-romance/welcome-to-the-black-parade",
      label: "Welcome to the Black Parade - My Chemical Romance",
    },
    {
      url: "https://soundcloud.com/linkin_park/papercut?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Papercut - Linkin Park",
    },
    {
      url: "https://soundcloud.com/evanescence/bring-me-to-life?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bring Me to Life - Evanescence",
    },
    {
      url: "https://soundcloud.com/gebran14/mia-paper-planes",
      label: "Paper Planes - MIA",
    },
    {
      url: "https://soundcloud.com/katenash/foundations-full-version",
      label: "Foundations - Kate Nash",
    },
    {
      url: "https://soundcloud.com/alien-ant-farm-official/smooth-criminal-album-version",
      label: "Smooth Criminal - Alien Ant Farm",
    },
    {
      url: "https://soundcloud.com/sum41/still-waiting-album-version-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Still Waiting - Sum 41",
    },
    {
      url: "https://soundcloud.com/arcticmonkeys/crying-lightning",
      label: "Crying Lightning - Arctic Monkeys",
    },
    {
      url: "https://soundcloud.com/tenaciousd/wonderboy-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Wonderboy - Tenacious D",
    },
    {
      url: "https://soundcloud.com/shakira/hips-dont-lie",
      label: "Hips Don't Lie - Shakira",
    },
    {
      url: "https://soundcloud.com/sum41/in-too-deep?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "In Too Deep - Sum 41",
    },
    {
      url: "https://soundcloud.com/evanescence/my-immortal?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "My Immortal - Evanescence",
    },
    {
      url: "https://soundcloud.com/bowling-for-soup/1985-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "1985 - Bowling For Soup",
    },
    {
      url: "https://soundcloud.com/panicatthedisco/i-write-sins-not-tragedies?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Write Sins Not Tragedies - Panic! at the Disco",
    },
    {
      url: "https://soundcloud.com/kellyclarksonofficial/since-u-been-gone-live",
      label: "Since U Been Gone - Kelly Clarkson",
    },
    {
      url: "https://soundcloud.com/destinys-child-official/survivor",
      label: "Survivor - Destiny's Child",
    },
    {
      url: "https://soundcloud.com/thescript/breakeven",
      label: "Breakeven - The Script",
    },
    {
      url: "https://soundcloud.com/britneyspears/toxic",
      label: "Toxic - Britney Spears",
    },
    {
      url: "https://soundcloud.com/kesha/tik-tok-tom-nevilles-crunk-med",
      label: "TiK ToK - Kesha",
    },
    {
      url: "https://soundcloud.com/natashabedingfieldofficial/unwritten",
      label: "Unwritten - Natasha Bedingfield",
    },
    {
      url: "https://soundcloud.com/leonalewis41705/bleeding-love",
      label: "Bleeding Love - Leona Lewis",
    },
    {
      url: "https://soundcloud.com/adelemusic/rolling-in-the-deep?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rolling in the Deep - Adele",
    },
    {
      url: "https://soundcloud.com/seankingston/beautiful-girls",
      label: "Beautiful Girls - Sean Kingston",
    },
    {
      url: "https://soundcloud.com/sarabareilles/love-song-1",
      label: "Love Song - Sara Bareilles",
    },
    {
      url: "https://soundcloud.com/blocparty/helicopter",
      label: "Helicopter - Bloc Party",
    },
    {
      url: "https://soundcloud.com/luckynumbermusic/darwin-deez-radar-detector",
      label: "Radar Detector - Darwin Deez",
    },
    {
      url: "https://soundcloud.com/theshins/so-says-i",
      label: "So Says I - The Shins",
    },
    {
      url: "https://soundcloud.com/kingsofleon/mollys-chambers",
      label: "Molly's Chambers - Kings of Leon",
    },
    {
      url: "https://soundcloud.com/razorlight-official/golden-touch-full-length",
      label: "Golden Touch - Razorlight",
    },
    {
      url: "https://soundcloud.com/the-coral/pass-it-on",
      label: "Pass It On - The Coral",
    },
    {
      url: "https://soundcloud.com/spoontheband/the-underdog-1",
      label: "The Underdog - Spoon",
    },
    {
      url: "https://soundcloud.com/the-pigeon-detectives/take-her-back-1",
      label: "Take Her Back - The Pigeon Detectives",
    },
    {
      url: "https://soundcloud.com/kaiserchiefs/never-miss-a-beat",
      label: "Never Miss A Beat - Kaiser Chiefs",
    },
    {
      url: "https://soundcloud.com/jamietmusic/sticks-n-stones",
      label: "Sticks 'N' Stones - Jamie T",
    },
    {
      url: "https://soundcloud.com/thekillers/mr-brightside-2",
      label: "Mr. Brightside - The Killers",
    },
    {
      url: "https://soundcloud.com/mileycyrus/party-in-the-u-s-a",
      label: "Party In the U.S.A. - Miley Cyrus",
    },
    {
      url: "https://soundcloud.com/atlanticrecords/estelle-american-boy-feat?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "American Boy - Estelle",
    },
    {
      url: "https://soundcloud.com/direalshaggy/it-wasnt-me?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "It Wasn't Me - Shaggy",
    },
    {
      url: "https://soundcloud.com/beyonce/crazy-in-love-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Crazy in Love - Beyoncé",
    },
    {
      url: "https://soundcloud.com/rihanna/umbrella?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Umbrella - Rihanna",
    },
    {
      url: "https://soundcloud.com/kooksmusic/naive?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Naive - The Kooks",
    },
    {
      url: "https://soundcloud.com/kanyewest/gold-digger-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Gold Digger - Kanye West",
    },
    {
      url: "https://soundcloud.com/samsparro/black-gold?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Black & Gold - Sam Sparro",
    },
    {
      url: "https://soundcloud.com/tiofficial/live-your-life-feat-rihanna?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Live Your Life - T.I.",
    },
    {
      url: "https://soundcloud.com/christinaaguilera/lady-marmalade-single-edit?in=christinaaguilera/sets/lady-marmalade-89333500&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lady Marmalade - Christina Aguilera, Lil' Kim, Maya, P!nk",
    },
    {
      url: "https://soundcloud.com/kingsofleon/sex-on-fire?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sex on Fire - Kings of Leon",
    },
    {
      url: "https://soundcloud.com/kanyewest/stronger-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Stronger - Kanye West",
    },
    {
      url: "https://soundcloud.com/nellyfurtado/maneater-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Maneater - Nelly Furtado",
    },
    {
      url: "https://soundcloud.com/aliciakeys/fallin?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Fallin' Alicia Keys",
    },
    {
      url: "https://soundcloud.com/dizzee-rascal/dance-wiv-me-radio-edit?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Dance Wiv Me - Dizzee Rascal",
    },
    {
      url: "https://soundcloud.com/florence-the-machine/youve-got-the-love?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You've Got the Love - Florence + the Machine",
    },
    {
      url: "https://soundcloud.com/gnarls-barkley-1/crazy?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Crazy - Gnarls Barkley",
    },
    {
      url: "https://soundcloud.com/beyonce/irreplaceable?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Irreplaceable - Beyoncé",
    },
    {
      url: "https://soundcloud.com/avrillavigne/complicated?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Complicated - Avril Lavigne",
    },
    {
      url: "https://soundcloud.com/markronson/valerie-version-revisited?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Valerie - Mark Ronson, Amy Winehouse",
    },
    {
      url: "https://soundcloud.com/thescript/the-man-who-cant-be-moved-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Man Who Can't Be Moved - The Script",
    },
    {
      url: "https://soundcloud.com/the-pussycat-dolls-official/dont-cha-radio-edit?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Don't Cha - The Pussycat Dolls",
    },
    {
      url: "https://soundcloud.com/coldplay/viva-la-vida?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Viva la Vida - Coldplay",
    },
    {
      url: "https://soundcloud.com/outkast-music/hey-ya-radio-mix-club-mix?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hey Ya! - Outkast",
    },
    {
      url: "https://soundcloud.com/eminemofficial/lose-yourself?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lose Yourself - Eminem",
    },
    {
      url: "https://soundcloud.com/maroon-5/she-will-be-loved-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "She Will Be Loved - Maroon 5",
    },
    {
      url: "https://soundcloud.com/taiocruz/break-your-heart?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Break Your Heart - Taio Cruz",
    },
    {
      url: "https://soundcloud.com/jay-z-official/empire-state-of-mind-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Empire State of Mind - JAY-Z, Alicia Keys",
    },
    {
      url: "https://soundcloud.com/usher-raymond-music/yeah?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Yeah! - Usher",
    },
    {
      url: "https://soundcloud.com/rihanna/rude-boy?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rude Boy - Rihanna",
    },
    {
      url: "https://soundcloud.com/onerepublic/apologize-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Apologize - OneRepublic",
    },
    {
      url: "https://soundcloud.com/cheryl-cole-official/fight-for-this-love?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Fight for This Love - Cheryl",
    },
    {
      url: "https://soundcloud.com/nellyfurtado/broken-strings?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Broken Strings - James Morrison, Nelly Furtado",
    },
    {
      url: "https://soundcloud.com/jasonmraz/im-yours?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I'm Yours - Jason Mraz",
    },
    {
      url: "https://soundcloud.com/lilyallen/smile-explicit-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Smile - Lily Allen",
    },
    {
      url: "https://soundcloud.com/jls-official/beat-again-radio-edit?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Beat Again - JLS",
    },
    {
      url: "https://soundcloud.com/jordinsparksofficial/no-air?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "No Air - Jordin Sparks",
    },
    {
      url: "https://soundcloud.com/la-roux-official/in-for-the-kill?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "In for the Kill - La Roux",
    },
    {
      url: "https://soundcloud.com/timbaland/the-way-i-are-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Way I Are - Timbaland",
    },
    {
      url: "https://soundcloud.com/robbiewilliams/rock-dj?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rock DJ - Robbie Williams",
    },
    {
      url: "https://soundcloud.com/justintimberlake/im-lovin-it?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I'm Lovin' It - Justin Timberlake",
    },
    {
      url: "https://soundcloud.com/katyperry/i-kissed-a-girl?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Kissed a Girl - Katie Perry",
    },
    {
      url: "https://soundcloud.com/ne-yo/closer-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Closer - Ne-Yo",
    },
    {
      url: "https://soundcloud.com/official-p-nk/so-what-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "So What - P!nk",
    },
    {
      url: "https://soundcloud.com/rihanna/only-girl-in-the-world-album?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Only Girl (In the World) - Rihanna",
    },
    {
      url: "https://soundcloud.com/outkast-music/ms-jackson?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Ms. Jackson - Outkast",
    },
    {
      url: "https://soundcloud.com/gwen-stefani/hollaback-girl-album-version-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hollaback Girl - Gwen Stefani",
    },
    {
      url: "https://soundcloud.com/razorlight-official/america?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "America - Razorlight",
    },
    {
      url: "https://soundcloud.com/kt-tunstall/suddenly-i-see?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Suddenly I See - KT Tunstall",
    },
    {
      url: "https://soundcloud.com/september-official/cry-for-you-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Cry for You - September",
    },
    {
      url: "https://soundcloud.com/davidguetta/when-love-takes-over-feat-16?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "When Love Takes Over - David Guetta, Kelly Rowland",
    },
    {
      url: "https://soundcloud.com/justintimberlake/sexyback-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "SexyBack - Justin Timberlake",
    },
    {
      url: "https://soundcloud.com/kingsofleon/use-somebody?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Use Somebody - Kings of Leon",
    },
    {
      url: "https://soundcloud.com/take-that-official/rule-the-world-radio-edit?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rule The World - Take That",
    },
    {
      url: "https://soundcloud.com/kaiserchiefs/i-predict-a-riot-2005-remaster?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Predict a Riot - Kaiser Chiefs",
    },
    {
      url: "https://soundcloud.com/nelly-official/dilemma?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Dilemma - Nelly, Kelly Rowland",
    },
    {
      url: "https://soundcloud.com/eve-official/let-me-blow-ya-mind-album-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Let Me Blow Ya Mind - Eve",
    },
    {
      url: "https://soundcloud.com/snowpatrol/chasing-cars-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Chasing Cars - Snow Patrol",
    },
    {
      url: "https://soundcloud.com/50_cent/in-da-club?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "In da Club - 50 Cent",
    },
    {
      url: "https://soundcloud.com/ladygaga/poker-face?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Poker Face - Lady Gaga",
    },
    {
      url: "https://soundcloud.com/duffy-official/mercy?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Mercy - Duffy",
    },
    {
      url: "https://soundcloud.com/scissorsisters/i-dont-feel-like-dancin-radio?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Don't Feel Like Dancin' - Scissor Sisters",
    },
    {
      url: "https://soundcloud.com/jenniferlopez/get-right?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Get Right - Jennifer Lopez",
    },
    {
      url: "https://soundcloud.com/daniel-bedingfield-official/gotta-get-thru-this-radio-edit?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Gotta Get Thru This - Daniel Bedingfield",
    },
    {
      url: "https://soundcloud.com/amy-winehouse/amy-winehouse-back-to-black?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Back to Black - Amy Winehouse",
    },
    {
      url: "https://soundcloud.com/nelly-official/hot-in-herre-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hot in Herre - Nelly",
    },
    {
      url: "https://soundcloud.com/ja-rule-official/always-on-time?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Always On Time - Ja Rule, Ashanti",
    },
    {
      url: "https://soundcloud.com/leannrimesofficial/last-thing-on-my-mind?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Last Thing On My Mind - Ronan Keating, LeAnn Rimes",
    },
    {
      url: "https://soundcloud.com/arcticmonkeys/i-bet-you-look-good-on-the?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Bet You Look Good on the Dancefloor - Arctic Monkeys",
    },
    {
      url: "https://soundcloud.com/ciara/one-two-step?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "One, Two Step - Ciara, Missy Elliot",
    },
    {
      url: "https://soundcloud.com/ladygaga/bad-romance?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bad Romance - Lady Gaga",
    },
    {
      url: "https://soundcloud.com/black-eyed-peas-official/i-gotta-feeling-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Gotta Feeling - Black Eyed Peas",
    },
    {
      url: "https://soundcloud.com/aliciakeys/if-i-aint-got-you-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "If I Ain't Got You - Alicia Keys",
    },
    {
      url: "https://soundcloud.com/daniel-merriweather-official/red?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Red - Daniel Merriweather",
    },
    {
      url: "https://soundcloud.com/boyzone-official/when-the-going-gets-tough?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "When the Going Gets Tough - Boyzone",
    },
    {
      url: "https://soundcloud.com/all-saints-official/pure-shores?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Pure Shores - All Saints",
    },
    {
      url: "https://soundcloud.com/take-that-official/patience-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Patience - Take That",
    },
    {
      url: "https://soundcloud.com/fatjoeofficial/whats-luv-feat-ashanti?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "What's Luv? - Fat Joe",
    },
    {
      url: "https://soundcloud.com/jamesblunt/youre-beautiful-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You're Beautiful - James Blunt",
    },
  ],
  "2010s": [
    {
      url: "https://soundcloud.com/pharrellwilliams-official/marilyn-monroe",
      label: "Marilyn Monroe - Pharrell Williams",
    },
    {
      url: "https://soundcloud.com/lordemusic/royals-1",
      label: "Royals - Lorde",
    },
    {
      url: "https://soundcloud.com/ritaora/anywhere",
      label: "Anywhere - Rita Ora",
    },
    {
      url: "https://soundcloud.com/omimusiconline/cheerleader-felix-jaehn",
      label: "Cheerleader (Felix Jaehn Remix Radio Edit) - OMI",
    },
    {
      url: "https://soundcloud.com/daftpunkofficialmusic/contact",
      label: "Contact - Daft Punk",
    },
    {
      url: "https://soundcloud.com/octobersveryown/drake-hotline-bling-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hotline Bling - Drake",
    },
    {
      url: "https://soundcloud.com/officialbirdy/keeping-your-head-up-1",
      label: "Keeping Your Head Up - Birdy",
    },
    {
      url: "https://soundcloud.com/ladygaga/applause",
      label: "Applause - Lady Gaga",
    },
    {
      url: "https://soundcloud.com/shakira/waka-waka-this-time-for",
      label: "Waka Waka (This time for Africa) - Shakira",
    },
    {
      url: "https://soundcloud.com/kanoofficial/3-wheel-ups-feat-wiley-giggs",
      label: "3 Wheel-Ups - Kano Feat Wiley & Giggs ",
    },
    {
      url: "https://soundcloud.com/linkin_park/heavy-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Heavy - Linkin Park",
    },
    {
      url: "https://soundcloud.com/edsheeran/shape-of-you",
      label: "Shape of You - Ed Sheeran",
    },
    {
      url: "https://soundcloud.com/gotye/3-somebody-that-i-used-to-know",
      label: "Somebody That I Used To Know - Gotye",
    },
    {
      url: "https://soundcloud.com/two-door-cinema-club/what-you-know-full-track",
      label: "What You Know - Two Door Cinema Club",
    },
    { url: "https://soundcloud.com/lizzomusic/juice", label: "Juice - Lizzo" },
    {
      url: "https://soundcloud.com/rudimental/feel-the-love-feat-john-1",
      label: "Feel The Love (feat. John Newman) - Rudimental",
    },
    {
      url: "https://soundcloud.com/foofighters/rope",
      label: "Rope - Foo Fighters",
    },
    { url: " https://soundcloud.com/phoenix/1901-1", label: "1901 - Phoenix" },
    {
      url: "https://soundcloud.com/justintimberlake/cant-stop-the-feeling-original",
      label: "CAN'T STOP THE FEELING! - Justin Timberlake",
    },
    {
      url: "https://soundcloud.com/adelemusic/skyfall-full-length",
      label: "Skyfall - Adele",
    },
    {
      url: "https://soundcloud.com/ceelogreen/fuck-you",
      label: "Fuck You - CeeLo Green",
    },
    {
      url: "https://soundcloud.com/loreen-official/euphoria?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Euphoria - Loreen",
    },
    {
      url: "https://soundcloud.com/robyn/dancing-on-my-own?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Dancing on My Own - Robyn",
    },
    {
      url: "https://soundcloud.com/dnceofficial/cake-by-the-ocean-1",
      label: "Cake By The Ocean - DNCE",
    },
    {
      url: "https://soundcloud.com/tovelo/tove-lo-talking-body",
      label: "Talking Body - Tove Lo",
    },
    {
      url: "https://soundcloud.com/interscope/lmfao-party-rock-anthem-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Party Rock Anthem - LMFAO",
    },
    {
      url: "https://soundcloud.com/source-music-catalogue/chk-chk-chk-slyd",
      label: "Slyd - !!!",
    },
    {
      url: "https://soundcloud.com/m83/midnight-city",
      label: "Midnight City - M83",
    },
    {
      url: "https://soundcloud.com/catfishandthebottlemen/cocoon-1",
      label: "Cocoon - Catfish and the Bottlemen",
    },
    {
      url: "https://soundcloud.com/marinadiamandis/oh-no",
      label: "Oh No! - MARINA",
    },
    {
      url: "https://soundcloud.com/janelle-monae/make-me-feel",
      label: "Make Me Feel - Janelle Monáe",
    },
    {
      url: "https://soundcloud.com/jacku/skrillex-and-diplo-present-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Where Are U Now - Jack U ft Justin Bieber",
    },
    {
      url: "https://soundcloud.com/florence-the-machine/shake-it-out?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Shake It Out - Florence + the Machine",
    },
    {
      url: "https://soundcloud.com/john-blacksmithy/far-east-movement-like-a-g6-feat-the-cataracs-dev?in=ziziey-slumberr/sets/bootleg",
      label: "Like A G6 - Far East Movement ",
    },
    {
      url: "https://soundcloud.com/whoismgmt/electric-feel",
      label: "Electric Feel - MGMT",
    },
    {
      url: "https://soundcloud.com/disturbed1/the-sound-of-silence?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Sound of Silence - Disturbed",
    },
    {
      url: "https://soundcloud.com/haimtime/want-you-back",
      label: "Want You Back - HAIM",
    },
    {
      url: "https://soundcloud.com/funofficial/fun-we-are-young-feat-janelle",
      label: "We Are Young - Fun. (feat. Janelle Monáe)",
    },
    {
      url: "https://soundcloud.com/billieeilish/bad-guy",
      label: "Bad Guy - Billie Eilish",
    },
    {
      url: "https://soundcloud.com/ceelogreen/its-ok",
      label: "It's Okay - Cee Lo Green",
    },
    {
      url: "https://soundcloud.com/radiohead/burn-the-witch",
      label: "Burn the Witch - Radiohead",
    },
    {
      url: "https://soundcloud.com/tame-impala/the-less-i-know-the-better",
      label: "The Less I Know The Better - Tame Impala ",
    },
    {
      url: "https://soundcloud.com/the-ting-tings/thats-not-my-name-radio-edit",
      label: "That's Not My Name - The Ting Tings",
    },
    {
      url: "https://soundcloud.com/eldjkb/get-lucky-original-version",
      label: "Get Lucky - Daft Punk (feat. Pharrell Williams & Nile Rodgers)",
    },
    {
      url: "https://soundcloud.com/jasonderulo/ridin-solo-edit",
      label: "Ridin' Solo - Jason Derulo",
    },
    {
      url: "https://soundcloud.com/mans-zelmerlow/heroes?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Heroes - Mans Zelmerlow",
    },
    {
      url: "https://soundcloud.com/charlixcx/1999-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "1999 - Charli XCX",
    },
    {
      url: "https://soundcloud.com/mumfordandsons/i-will-wait",
      label: "I Will Wait - Mumford & Sons",
    },
    {
      url: "https://soundcloud.com/the1975/its-not-living-if-its-not-with",
      label: "It's Not Living (If It's Not With You) - The 1975",
    },
    {
      url: "https://soundcloud.com/thechats-music/pub-feed",
      label: "Pub Feed - The Chats",
    },
    {
      url: "https://soundcloud.com/fosterthepeoplemusic/pumpedupkicks",
      label: "Pumped Up Kicks - Foster The People",
    },
    {
      url: "https://soundcloud.com/samsmithworld/stay-with-me-2",
      label: "Stay With Me - Sam Smith",
    },
    {
      url: "https://soundcloud.com/haimtime/the-wire",
      label: "The Wire - HAIM",
    },
    {
      url: "https://soundcloud.com/childish-gambino/this-is-america",
      label: "This Is America - Childish Gambino",
    },
    {
      url: "https://soundcloud.com/christineandthequeens/tilted?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Tilted - Christine and the Queens",
    },
    {
      url: "https://soundcloud.com/beachfossils/vacation",
      label: "Vacation - Beach Fossils",
    },
    {
      url: "https://soundcloud.com/wearerockcity/locked-away-feat-adam-levine",
      label: "Locked Away - R. City, Adam Levine",
    },
    {
      url: "https://soundcloud.com/navigation/the-script",
      label: "The Man Who Can't Be Moved - The Script",
    },
    {
      url: "https://soundcloud.com/calvinharris/summer",
      label: "Summer - Calvin Harris",
    },
    {
      url: "https://soundcloud.com/ragnbonemanuk/skin",
      label: "Skin - Rag'n'Bone Man",
    },
    {
      url: "https://soundcloud.com/george-ezra/paradise",
      label: "Paradise - George Ezra",
    },
    {
      url: "https://soundcloud.com/mileycyrus/wrecking-ball",
      label: "Wrecking Ball - Miley Cyrus",
    },
    {
      url: "https://soundcloud.com/calvinharris/feels-1",
      label: "Feels - Calvin Harris",
    },
    {
      url: "https://soundcloud.com/markronson/uptown-funk",
      label: "Uptown Funk (feat. Bruno Mars) - Mark Ronson",
    },
    {
      url: "https://soundcloud.com/pitbull/pitbull-timber-featuring-ke-ha",
      label: "Timber (feat. Ke$ha) - Pitbull",
    },
    {
      url: "https://soundcloud.com/george-ezra/budapest-1",
      label: "Budapest - George Ezra",
    },
    {
      url: "https://soundcloud.com/bastilleuk/pompeii?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Pompeii - Bastille",
    },
    {
      url: "https://soundcloud.com/carlyraejepsen/call-me-maybe-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Call Me Maybe - Carly Rae Jepsen",
    },
    {
      url: "https://soundcloud.com/tinietempah/pass-out-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Pass Out - Tinie Tempah",
    },
    {
      url: "https://soundcloud.com/hozier/take-me-to-church-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Take Me To Church - Hozier",
    },
    {
      url: "https://soundcloud.com/mikeposner/i-took-a-pill-in-ibiza?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Took a Pill in Ibiza - Mike Posner",
    },
    {
      url: "https://soundcloud.com/luisfonsiofficial/despacito?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Despacito - Luis Fonsi, Daddy Yankee",
    },
    {
      url: "https://soundcloud.com/taylorswiftofficial/blank-space?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Blank Space - Taylor Swift",
    },
    {
      url: "https://soundcloud.com/onerepublic/counting-stars?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Counting Stars - OneRepublic",
    },
    {
      url: "https://soundcloud.com/thechainsmokers/closer?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Closer - The Chainsmokers",
    },
    {
      url: "https://soundcloud.com/bennyblanco/eastside?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Eastside - benny blanco, Halsey, Khalid",
    },
    {
      url: "https://soundcloud.com/rihanna/we-found-love?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "We Found Love - Rihanna",
    },
    {
      url: "https://soundcloud.com/nickiminaj/starships-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Starships - Nicki Minaj",
    },
    {
      url: "https://soundcloud.com/siamusic/cheap-thrills-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Cheap Thrills - Sia",
    },
    {
      url: "https://soundcloud.com/nicoandvinz/nico-vinz-am-i-wrong-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Am I Wrong - Nico & Vinz",
    },
    {
      url: "https://soundcloud.com/brunomars/locked-out-of-heaven-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Locked Out of Heaven - Bruno Mars",
    },
    {
      url: "https://soundcloud.com/vancejoy/02-riptide?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Riptide - Vance Joy",
    },
    {
      url: "https://soundcloud.com/siamusic/sia-chandelier-piano-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Chandelier - Sia",
    },
    {
      url: "https://soundcloud.com/nickiminaj/super-bass-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Super Bass - Nicki Minaj",
    },
    {
      url: "https://soundcloud.com/eminemofficial/love-the-way-you-lie-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Love the Way You Lie - Eminem",
    },
    {
      url: "https://soundcloud.com/officialshawnmendes/stitches?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Stitches - Shawn Mendes",
    },
    {
      url: "https://soundcloud.com/kealasettle/this-is-me?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "This is Me - Keala Settle",
    },
    {
      url: "https://soundcloud.com/taiocruz/dynamite?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Dynamite - Taio Cruz",
    },
    {
      url: "https://soundcloud.com/iggy-azalea-official/fancy?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Fancy - Iggy Azalea",
    },
    {
      url: "https://soundcloud.com/imaginedragons/thunder-final?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Thunder - Imagine Dragons",
    },
    {
      url: "https://soundcloud.com/arianagrande/one-last-time-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "One Last Time - Ariana Grande",
    },
    {
      url: "https://soundcloud.com/aviciiofficial/wake-me-up-radio-edit?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Wake Me Up - Avicii",
    },
    {
      url: "https://soundcloud.com/jessiejofficial/bang-bang?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bang Bang - Jessie J, Ariana Grande, Nicki Minaj",
    },
    {
      url: "https://soundcloud.com/jenniferlopez/on-the-floor-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "On the Floor - Jennifer Lopez, Pitbull",
    },
    {
      url: "https://soundcloud.com/adelemusic/rolling-in-the-deep?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rolling in the Deep - Adele",
    },
    {
      url: "https://soundcloud.com/jessiejofficial/price-tag-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Price Tag - Jessie J",
    },
    {
      url: "https://soundcloud.com/arianagrande/problem-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Problem - Ariana Grande, Iggy Azalea",
    },
    {
      url: "https://soundcloud.com/adelemusic/hello?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hello - Adele",
    },
    {
      url: "https://soundcloud.com/theweeknd/cant-feel-my-face-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Can't Feel My Face - The Weeknd",
    },
    {
      url: "https://soundcloud.com/walk-the-moon/walk-the-moon-shut-up-and-dance?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Shut Up and Dance - WALK THE MOON",
    },
    {
      url: "https://soundcloud.com/ladygaga/shallow?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Shallow - Lady Gaga, Bradley Cooper",
    },
    {
      url: "https://soundcloud.com/officialswedishhousemafia/dont-you-worry-child-radio?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Don't You Worry Child - Swedish House Mafia",
    },
    {
      url: "https://soundcloud.com/avamax/sweetbutpsycho?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sweet but Psycho - Ava Max",
    },
    {
      url: "https://soundcloud.com/little-mix/black-magic?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Black Magic - Little Mix",
    },
    {
      url: "https://soundcloud.com/iconapop/i-love-it-feat-charli-xcx?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Love It - Icona Pop",
    },
    {
      url: "https://soundcloud.com/fifthharmony/work-from-home?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Work from Home - Fifth Harmony",
    },
    {
      url: "https://soundcloud.com/majorlazer/major-lazer-dj-snake-lean-on-feat-mo?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lean On - DJ Snake, Major Lazer",
    },
    {
      url: "https://soundcloud.com/portugaltheman/feel-it-still-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Feel It Still - Portugal. The Man",
    },
    {
      url: "https://soundcloud.com/usher-raymond-music/dj-got-us-fallin-in-love?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "DJ Got Us Fallin' in Love - Usher",
    },
    {
      url: "https://soundcloud.com/maroon-5/moves-like-jagger?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Moves Like Jagger - Maroon 5",
    },
    {
      url: "https://soundcloud.com/french-montana-official/unforgettable-feat-swae-lee?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Unforgettable - French Montana",
    },
    {
      url: "https://soundcloud.com/dualipa/new-rules-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "New Rules - Dua Lipa",
    },
    {
      url: "https://soundcloud.com/justinbieber/sorry?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sorry - Justin Bieber",
    },
    {
      url: "https://soundcloud.com/nickjonasmusic/jealous?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Jealous - Nick Jonas",
    },
    {
      url: "https://soundcloud.com/edsheeran/thinking-out-loud?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Thinking Out Loud - Ed Sheeran",
    },
    {
      url: "https://soundcloud.com/katyperry/teenage-dream?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Teenage Dream - Katy Perry",
    },
    {
      url: "https://soundcloud.com/cleanbandit/tears-feat-louisa-johnson?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Tears - Clean Bandit, Louise Johnson",
    },
    {
      url: "https://soundcloud.com/jasonderulo/want-to-want-me?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Want to Want Me - Jason Derulo",
    },
    {
      url: "https://soundcloud.com/jamesbaymusic/hold-back-the-river-4?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hold Back the River - James Bay",
    },
    {
      url: "https://soundcloud.com/james-arthur-official/impossible?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Impossible - James Arthur",
    },
    {
      url: "https://soundcloud.com/octobersveryown/drake-one-dance?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "One Dance - Drake",
    },
    {
      url: "https://soundcloud.com/official_flo/good-feeling?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Good Feeling - Flo Rida",
    },
    {
      url: "https://soundcloud.com/brunomars/just-the-way-you-are-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Just the Way You Are - Bruno Mars",
    },
    {
      url: "https://soundcloud.com/lewiscapaldi/someone-you-loved-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Someone You Loved - Lewis Capaldi",
    },
    {
      url: "https://soundcloud.com/johnlegend/all-of-me-4?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "All of Me - John Legend",
    },
    {
      url: "https://soundcloud.com/rihanna/diamonds-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Diamonds - Rihanna",
    },
    {
      url: "https://soundcloud.com/maroon-5/sugar-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sugar - Maroon 5",
    },
    {
      url: "https://soundcloud.com/george-ezra/shotgun?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Shotgun - George Ezra",
    },
    {
      url: "https://soundcloud.com/camilacabello/havana-feat-young-thug?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Havana - Camila Cabello",
    },
    {
      url: "https://soundcloud.com/disclosuremusic/latch-ft-sam-smith?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Latch - Disclosure",
    },
    {
      url: "https://soundcloud.com/ladygaga/born-this-way-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Born This Way - Lady Gaga",
    },
    {
      url: "https://soundcloud.com/adelemusic/someone-like-you?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Someone Like You - Adele",
    },
    {
      url: "https://soundcloud.com/rihanna/whats-my-name-album-version?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "What's My Name? - Rihanna",
    },
    {
      url: "https://soundcloud.com/yearsandyears/king?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "King - Years & Years",
    },
    {
      url: "https://soundcloud.com/lana-del-rey/summertime-sadness?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Summer time Sadness - Lana Del Rey",
    },
    {
      url: "https://soundcloud.com/katyperry/roar?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Roar - Katy Perry",
    },
    {
      url: "https://soundcloud.com/coldplay/paradise?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Paradise - Coldplay",
    },
    {
      url: "https://soundcloud.com/onedirectionmusic/story-of-my-life?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Story of My Life - One Direction",
    },
    {
      url: "https://soundcloud.com/twentyonepilots/stressed-out?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Stressed Out - twenty one pilots",
    },
    {
      url: "https://soundcloud.com/michaelbuble/havent-met-you-yet?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Haven't Met You Yet - Michael Bublé",
    },
    {
      url: "https://soundcloud.com/elliegoulding/starry-eyed?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Starry Eyed - Ellie Goulding",
    },
    {
      url: "https://soundcloud.com/thewanted/the-wanted-all-time-low?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "All Time Low - The Wanted",
    },
    {
      url: "https://soundcloud.com/steps20027/scared-of-the-dark?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Scared of the Dark - Steps",
    },
    {
      url: "https://soundcloud.com/example/kickstarts?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Kickstarts - Example",
    },
    {
      url: "https://soundcloud.com/selena-gomez-official/good-for-you?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Good For You - Selena Gomez",
    },
    {
      url: "https://soundcloud.com/meghantrainorofficial/all-about-that-bass?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Meghan Trainor - All About That Bass",
    },
  ],
  "2020s": [
    {
      url: "https://soundcloud.com/machinegunkelly/bloody-valentine",
      label: "Bloody Valentine - Machine Gun Kelly",
    },
    {
      url: "https://soundcloud.com/rinasawayama/xs?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "XS - Rina Sawayama",
    },
    {
      url: "https://soundcloud.com/harrystyles/falling?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Falling - Harry Styles",
    },
    {
      url: "https://soundcloud.com/calvinharris/by-your-side",
      label: "By Your Side feat. Tom Grennan - Calvin Harris",
    },
    {
      url: "https://soundcloud.com/billieeilish/happier-than-ever",
      label: "Happier Than Ever - Billie Eilish",
    },
    {
      url: "https://soundcloud.com/dream-nails/kiss-my-fist-2",
      label: "Kiss My Fist - DREAM NAILS",
    },
    {
      url: "https://soundcloud.com/edsheeran/ed-sheeran-shivers",
      label: "Shivers - Ed Sheeran",
    },
    {
      url: "https://soundcloud.com/hotellux/tabloid-newspaper",
      label: "Tabloid Newspaper - Hotel Lux",
    },
    {
      url: "https://soundcloud.com/samfendermusic-1/seventeen-going-under",
      label: "Seventeen Going Under - Sam Fender",
    },
    {
      url: "https://soundcloud.com/dualipa/break-my-heart",
      label: "Break my heart - Dua Lipa",
    },
    {
      url: "https://soundcloud.com/jeandawson/menthol-feat-mac-demarco",
      label: "MENTHOL* - Jean Dawson",
    },
    {
      url: "https://soundcloud.com/coldplay/my-universe",
      label: "My Universe - Coldplay X BTS",
    },
    {
      url: "https://soundcloud.com/rudekidmusic/essex-girls-feat-jaykae-silky",
      label: "Essex Girls - Rude Kid Feat Jaykae, Silky & Janice Robinson",
    },
    {
      url: "https://soundcloud.com/becky-hill-official/my-heart-goes-la-di-da",
      label: "My Heart Goes (La Di Da) - Becky Hill",
    },
    {
      url: "https://soundcloud.com/mileycyrus/midnight-sky?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Midnight Sky - Miley Cyrus",
    },
    {
      url: "https://soundcloud.com/lizzomusic/rumors?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rumors (feat Cardi B) - Lizzo",
    },
    {
      url: "https://soundcloud.com/dualipa/physical",
      label: "Physical - Dua Lipa",
    },
    {
      url: "https://soundcloud.com/moses17591/who-needs-the-money",
      label: "Who Needs the Money? - MOSES",
    },
    { url: "https://soundcloud.com/foals/2am", label: "2am - Foals" },
    {
      url: "https://soundcloud.com/wetleg/chaise-longue",
      label: "Chaise Longue - Wet Leg",
    },
    {
      url: "https://soundcloud.com/caribouband/caribou-never-come-back",
      label: "Never Come Back - Caribou",
    },
    {
      url: "https://soundcloud.com/user-251587705/09-i-hate-you-rb-mix-082221",
      label: "I Hate U - SZA",
    },
    {
      url: "https://soundcloud.com/iamcardib/wap-feat-megan-thee-stallion",
      label: "WAP - Cardi B ft. Megan Thee Stallion",
    },
    {
      url: "https://soundcloud.com/lauranhibberd/sweat-patch",
      label: "Sweat Patch - Lauran Hibberd",
    },
    {
      url: "https://soundcloud.com/thepalewhite/swim-for-your-life-1",
      label: "Swim For Your Life - The Pale White",
    },
    {
      url: "https://soundcloud.com/glassanimals/heat-waves",
      label: "Heat Waves - Glass Animals",
    },
    {
      url: "https://soundcloud.com/maneskin/zitti-e-buoni?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Zitti E Buoni - Maneskin",
    },
    {
      url: "https://soundcloud.com/gorillaz/momentary-bliss-feat-slowthai",
      label: "Momentary Bliss - Gorillaz",
    },
    {
      url: "https://soundcloud.com/billieeilish/nda-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "NDA - Billie Eilish",
    },
    {
      url: "https://soundcloud.com/samfendermusic-1/saturday",
      label: "Saturday - Sam Fender",
    },
    {
      url: "https://soundcloud.com/joelcorry/head-heart",
      label: "Head & Heart (feat. MNEK) - Joel Corry",
    },
    {
      url: "https://soundcloud.com/greenday/oh-yeah",
      label: "Ow Yeah! - Green Day",
    },
    {
      url: "https://soundcloud.com/nationoflanguage/this-fractured-mind-1",
      label: "This Fractured Mind - Nation of Language",
    },
    {
      url: "https://soundcloud.com/circawaves/jacqueline",
      label: "Jacqueline - Circa Waves",
    },
    {
      url: "https://soundcloud.com/acdcofficial/shot-in-the-dark?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Shot In the Dark - AC/DC",
    },
    {
      url: "https://soundcloud.com/willie-j-healey",
      label: "For You - Willie J Healey",
    },
    {
      url: "https://soundcloud.com/powfu/death-bed-prod-otterpop",
      label: "Death Bed - Powfu",
    },
    {
      url: "https://soundcloud.com/elton-john/cold-heart-pnau-remix",
      label: "Cold Heart (PNAU Remix) - Elton John & Dua Lipa",
    },
    {
      url: "https://soundcloud.com/charlixcx/good-ones?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Good Ones - Charli XCX",
    },
    {
      url: "https://soundcloud.com/bring-me-the-horizon/parasite-eve",
      label: "Parasite Eve - Bring Me The Horizon",
    },
    {
      url: "https://soundcloud.com/taylorswiftofficial/champagne-problems?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "champagne problems - Taylor Swift",
    },
    {
      url: "https://soundcloud.com/themysterines/whos-ur-girl-single",
      label: "Who's Ur Girl - The Mysterines",
    },
    {
      url: "https://soundcloud.com/arianagrande/thank-u-next",
      label: "thank u, next - Ariana Grande",
    },
    {
      url: "https://soundcloud.com/christineandthequeens/christine-and-the-488204717",
      label: "People I've Been Sad - Christine and the Queens",
    },
    {
      url: "https://soundcloud.com/auroraaksnes/cure-for-me",
      label: "Cure For Me - AURORA",
    },
    {
      url: "https://soundcloud.com/oliviarodrigo/good-4-u-1",
      label: "Good 4 u - Olivia Rodrigo",
    },
    {
      url: "https://soundcloud.com/rinasawayama/this-hell?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "This Hell - Rina Sawayama",
    },
    {
      url: "https://soundcloud.com/imaginedragons/enemy-from-the-series-arcane-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Enemy - Imagine Dragons",
    },
    {
      url: "https://soundcloud.com/spiderverse/butter?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Butter - BTS",
    },
    {
      url: "https://soundcloud.com/billieeilish/therefore-i-am?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Therefore I Am - Billie Eilish",
    },
    {
      url: "https://soundcloud.com/user-115432156/bts-dynamite?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Dynamite - BTS",
    },
    {
      url: "https://soundcloud.com/samryderlondon/space-man?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Space Man - Sam Ryder",
    },
    {
      url: "https://soundcloud.com/bo-burnham/all-eyes-on-me?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "All Eyes On Me - Bo Burnham",
    },
    {
      url: "https://soundcloud.com/avamax/kings-queens?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Kings & Queens - Ava Max",
    },
    {
      url: "https://soundcloud.com/lizzomusic/about-damn-time?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "About Damn Time - Lizzo",
    },
    {
      url: "https://soundcloud.com/silverbacks-music/siren",
      label: "Sirens - Silverbacks",
    },
    {
      url: "https://soundcloud.com/cannonstheband/bad-dream-1",
      label: "Bad Dream - Cannons",
    },
    {
      url: "https://soundcloud.com/leothekind/aint-nobody-gon-judge",
      label: "Ain't Nobody Gon' Judge - Leo The Kind",
    },
    {
      url: "https://soundcloud.com/charlieputh/light-switch",
      label: "Light Switch - Charlie Puth",
    },
    {
      url: "https://soundcloud.com/soccermommyofficial/circle-the-drain",
      label: "circle the drain - Soccer Mommy",
    },
    {
      url: "https://soundcloud.com/ristonaroney/bottle-rocket",
      label: "Bottle Rocket - Briston Maroney",
    },
    {
      url: "https://soundcloud.com/maneskin/supermodel",
      label: "SUPERMODEL - Måneskin",
    },
    {
      url: "https://soundcloud.com/windser/memory",
      label: "Memory - Windser",
    },
    {
      url: "https://soundcloud.com/secret-service-862007284/industry-baby",
      label: "INDUSTRY BABY (feat. Jack Harlow0 - Lil Nas X",
    },
    {
      url: "https://soundcloud.com/theanxiety/meet-me-at-our-spot",
      label: "Meet Me At Our Spot - THE ANXIETY",
    },
    {
      url: "https://soundcloud.com/japanesebreakfast/be-sweet",
      label: "Be Sweet - Japanese Breakfast",
    },
    {
      url: "https://soundcloud.com/theweeknd/blinding-lights?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Blinding Lights - The Weeknd",
    },
    {
      url: "https://soundcloud.com/joelcorry/lonely?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lonely - Joel Corry",
    },
    {
      url: "https://soundcloud.com/24kgoldn/mood-feat-iann-dior?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Mood - 24kGoldn",
    },
    {
      url: "https://soundcloud.com/arianagrande/ariana-grande-positions?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "positions - Ariana Grande",
    },
    {
      url: "https://soundcloud.com/wesnelson-music/see-nobody?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "See Nobody - Wes Nelson",
    },
    {
      url: "https://soundcloud.com/diplo/paul-woolford-diplo-kareen-2?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Looking for Me - Paul Woolford, Diplo",
    },
    {
      url: "https://soundcloud.com/stormzy1/own-it-feat-ed-sheeran-burna?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Own It - Stormzy",
    },
    {
      url: "https://soundcloud.com/halseymusic/halsey-you-should-be-sad?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "You should be sad - Halsey",
    },
    {
      url: "https://soundcloud.com/dj-regard-1/secrets?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Secrets - Regard, RAYE",
    },
    {
      url: "https://soundcloud.com/little-mix/little-mix-sweet-melody?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sweet Melody - Little Mix",
    },
    {
      url: "https://soundcloud.com/topicmusic/breaking-me?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Breaking Me - Topic, A7S",
    },
    {
      url: "https://soundcloud.com/mabelmcvey/boyfriend?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Boyfriend - Mabel",
    },
    {
      url: "https://soundcloud.com/becky-hill-official/better-off-without-you-shift?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Better Off Without You - Becky Hill, Shift K3Y",
    },
    {
      url: "https://soundcloud.com/youngtbugsey/dont-rush-feat-headie-one?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Don't Rush - Young T & Bugsey",
    },
    {
      url: "https://soundcloud.com/cleanbandit/tick-tock-feat-24kgoldn?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Tick Tock - Clean Bandit",
    },
    {
      url: "https://soundcloud.com/jawsh685/savage-love-laxed-siren-beat-3?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Savage Love - Jawsh 685, Jason Derulo",
    },
    {
      url: "https://soundcloud.com/nathandawe/lighter-feat-ksi?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lighter - Nathan Dawe",
    },
    {
      url: "https://soundcloud.com/jasonderulo/take-you-dancing?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Take You Dancing - Jason Derulo",
    },
    {
      url: "https://soundcloud.com/samsmithworld/diamonds?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Diamonds - Sam Smith",
    },
    {
      url: "https://soundcloud.com/meduzamusic/paradise-feat-dermot-kennedy?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Paradise - Meduza",
    },
    {
      url: "https://soundcloud.com/ladygaga/rain-on-me-with-ariana-grande?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Rain On Me - Lady Gaga, Ariana Grande",
    },
    {
      url: "https://soundcloud.com/postmalone/one-right-now?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "One Right Now - Post Malone, The Weeknd",
    },
    {
      url: "https://soundcloud.com/little-mix/little-mix-break-up-song?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Break Up Song - Little Mix",
    },
    {
      url: "https://soundcloud.com/justinbieber/justin-bieber-holy-feat-chance?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Holy - Justin Bieber",
    },
    {
      url: "https://soundcloud.com/shane-codd/shane-codd-get-out-my-head?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Get Out My Head - Shane Codd",
    },
    {
      url: "https://soundcloud.com/niallhoranmusic/no-judgement?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "No Judgement - Niall Horan",
    },
    {
      url: "https://soundcloud.com/dermot-kennedy-777714337/giants?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Giants - Dermot Kennedy",
    },
    {
      url: "https://soundcloud.com/220kid/dont-need-love-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Don't Need Love - 220 KID",
    },
    {
      url: "https://soundcloud.com/amalaofficial/say-so?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Say So - Doja Cat",
    },
    {
      url: "https://soundcloud.com/harrystyles/watermelon-sugar?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Watermelon Sugar - Harry Styles",
    },
    {
      url: "https://soundcloud.com/samfischermusic/this-city-sam-fischer?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "This City - Sam Fischer",
    },
    {
      url: "https://soundcloud.com/dualipa/levitating?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Levitating - Dua Lipa",
    },
    {
      url: "https://soundcloud.com/edsheeran/ed-sheeran-bad-habits?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bad Habits - Ed Sheeran",
    },
    {
      url: "https://soundcloud.com/adelemusic/easy-on-me?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Easy On Me - Adele",
    },
    {
      url: "https://soundcloud.com/ellahenderson/lets-go-home-together?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Let's Go Home Together - Ella Henderson",
    },
    {
      url: "https://soundcloud.com/jonasuofficial/black-magic-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Black Magic - Jonasu",
    },
    {
      url: "https://soundcloud.com/becky-hill-official/remember?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Remember - Becky Hill, David Guetta",
    },
    {
      url: "https://soundcloud.com/tomgrennanmusic/little-bit-of-love?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Little Bit of Love - Tom Grennan",
    },
    {
      url: "https://soundcloud.com/theweeknd/the-weeknd-ariana-grande-save?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Save Your Tears - The Weeknd, Ariana Grande",
    },
    {
      url: "https://soundcloud.com/joelcorry/out-out-feat-charli-xcx?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "OUT OUT - Joel Corry, Charli XCX",
    },
    {
      url: "https://soundcloud.com/sigalamusic/melody?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Melody - Sigala",
    },
    {
      url: "https://soundcloud.com/mimiwebb/good-without?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Good Without - Mimi Webb",
    },
    {
      url: "https://soundcloud.com/dermot-kennedy-777714337/better-days?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Better Days - Dermot Kennedy",
    },
    {
      url: "https://soundcloud.com/maisie-peters/psycho?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Psycho - Maisie Peters",
    },
    {
      url: "https://soundcloud.com/coldplay/higher-power?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Higher Power - Coldplay",
    },
    {
      url: "https://soundcloud.com/iamannemarie/anne-marie-x-little-mix-kiss-4?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Kiss My (Uh Oh) - Anne-Marie, Little Mix",
    },
    {
      url: "https://soundcloud.com/camilacabello/bam-bam-feat-ed-sheeran?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bam Bam - Camila Cabello",
    },
    {
      url: "https://soundcloud.com/davidguetta/crazy-what-love-can-do?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Crazy What Love Can Do - David Guetta",
    },
    {
      url: "https://soundcloud.com/harrystyles/as-it-was?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "As It Was - Harry Styles",
    },
    {
      url: "https://soundcloud.com/tiesto/the-motto?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "The Motto - Tiësto, Ava Max",
    },
    {
      url: "https://soundcloud.com/charlixcx/beg-for-you-feat-rina-sawayama?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Beg for You - Charli XCX",
    },
    {
      url: "https://soundcloud.com/catburnsmusic/go?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "go - Cat Burns",
    },
    {
      url: "https://soundcloud.com/avamax/maybe-youre-the-problem?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Maybe You're the Problem - Ava Max",
    },
    {
      url: "https://soundcloud.com/cleanbandit/everything-but-you-feat-a7s?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Everything But You - Clean Bandit",
    },
    {
      url: "https://soundcloud.com/gayle/abc-nicer?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "abcdefu - GAYLE",
    },
    {
      url: "https://soundcloud.com/megan-thee-stallion/sweetest-pie?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sweetest Pie - Megan Thee Stallion",
    },
    {
      url: "https://soundcloud.com/fireboydml/peru?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Peru - Fireboy DML",
    },
    {
      url: "https://soundcloud.com/subwoolfer/give-that-wolf-a-banana?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Give That Wolf A Banana - Subwoolfer",
    },
    {
      url: "https://soundcloud.com/iamtomwalker/serotonin-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Serotonin - Tom Walker",
    },
    {
      url: "https://soundcloud.com/steps20027/what-the-future-holds-single?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "What The Future Holds - Steps",
    },
    {
      url: "https://soundcloud.com/chvrches/clearest-blue?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Clearest Blue - CHVRCHES",
    },
    {
      url: "https://soundcloud.com/yearsandyears/sweet-talker?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sweet Talker - Years & Years",
    },
    {
      url: "https://soundcloud.com/spinninrecords/lumx-halo-feat-pia-maria-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Halo - LUM!X",
    },
    {
      url: "https://soundcloud.com/normandie14318/holy-water?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Holy Water - Normandie",
    },
    {
      url: "https://soundcloud.com/mabelmcvey/mad-love?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Mad Love - Mabel",
    },
    {
      url: "https://soundcloud.com/odenfatzo/lauren-claptone-remix?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lauren (I Can't Stay Forever) - Oden, Fatzo, Claptone",
    },
    {
      url: "https://soundcloud.com/thisissigrid/bad-life?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Bad Life - Sigrid",
    },
    {
      url: "https://soundcloud.com/florence-the-machine/my-love-edit?in=florence-the-machine/sets/my-love-810599655&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "My Love - Florence + the Machine",
    },
    {
      url: "https://soundcloud.com/tate-mcrae/shes-all-i-wanna-be?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "she's all i wanna be - Tate McRae",
    },
    {
      url: "https://soundcloud.com/palewaves/lies?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Lies - Pale Waves",
    },
    {
      url: "https://soundcloud.com/oliverheldens/oliver-heldens-feat-nile?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "I Was Made For Lovin' You - Oliver Heldens",
    },
    {
      url: "https://soundcloud.com/tovelo/no-one-dies-from-love?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "No One Dies From Love - Tove Lo",
    },
    {
      url: "https://soundcloud.com/yearsandyears/muscle?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Muscle - Years & Years",
    },
    {
      url: "https://soundcloud.com/alesso/alesso-katy-perry-when-im-gone?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "When I'm Gone - Alesso & Katy Perry",
    },
    {
      url: "https://soundcloud.com/wiffygriffy/head-on-fire-feat-king?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Head on Fire - Griff & Sigrid",
    },
    {
      url: "https://soundcloud.com/jasonderulo/love-not-war-the-tampa-beat?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Love Not War - Nuka, Jason Derulo",
    },
    {
      url: "https://soundcloud.com/normandie14318/hostage?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hostage - Normandie",
    },
    {
      url: "https://soundcloud.com/ladygaga/hold-my-hand?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Hold My Hand - Lady Gaga",
    },
    {
      url: "https://soundcloud.com/iamfoxes/sky-love?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Sky Love - Foxes",
    },
    {
      url: "https://soundcloud.com/george-ezra/green-green-grass?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      label: "Green Green Grass - George Ezra",
    },
  ],
};
