import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import {
  CueButton,
  Guesses,
  InfoPanel,
  Player,
  ResultsModal,
} from "../components";
import { usePlayer } from "../utils";
import { MAX_ATTEMPTS } from "../constants";
import { differenceInDays } from "date-fns";
import format from "format-duration";
import { Eject } from "../icons";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const ButtonRow = styled.div`
  display: flex;
`;

const EjectButton = styled(Link)`
  width: 80px;
  margin: 5px;
  margin-left: 15px;
  height: 80px;
  background-color: white;
  flex: 0 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #333333;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: background-color 0.25s;

  svg {
    fill: white;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  }

  &:hover {
    background-color: #444444;
  }
`;

const START_DATE = new Date(2022, 3, 21);

const dayIndex = differenceInDays(new Date(), START_DATE);

const loadData = (decade, data) =>
  JSON.parse(localStorage.getItem(`data.${decade}`))?.[dayIndex]?.[data];

export const Decade = ({ songs, decade, title }) => {
  const [turn, setTurn] = useState(loadData(decade, "turn") || 0);
  const [attempts, setAttempts] = useState(loadData(decade, "attempts") || []);
  const [guess, setGuess] = useState(
    loadData(decade, "guess") || { time: null, value: "" }
  );
  const [result, setResult] = useState(loadData(decade, "result"));

  const [showResultsModal, toggleResultsModal] = useState(false);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(`data.${decade}`)) || {};
    data[dayIndex] = { attempts, guess, result, turn };
    localStorage.setItem(`data.${decade}`, JSON.stringify(data));
  }, [turn, result, attempts, guess]);

  const playerRef = useRef();

  const correctAnswer = songs[dayIndex % 146];

  const handleStop = (time) => setGuess((existing) => ({ ...existing, time }));

  useEffect(() => {
    if (!loaded) return;
    if (attempts.length === 0) return;
    if (attempts.find((attempt) => attempt.value === correctAnswer.label)) {
      return setResult("win");
    }
    if (attempts.length === MAX_ATTEMPTS) {
      return setResult("lose");
    }
    setTurn((x) => x + 1);
    seek(0);
    setGuess({ time: null, value: "" });
  }, [attempts]);

  useEffect(() => {
    if (!result) return;
    toggleResultsModal(true);
  }, [result]);

  const { isPlaying, seek, start, stop, progress, loaded } = usePlayer(
    playerRef,
    handleStop
  );

  const handlePointerDown = () => {
    if (!!guess.time) return;
    start();
  };

  return (
    <>
      <ResultsModal
        close={() => toggleResultsModal(false)}
        isOpen={showResultsModal}
        attempts={attempts}
        correctAnswer={correctAnswer}
        result={result}
        decade={decade}
        dayIndex={dayIndex}
      />
      <Player ref={playerRef} url={correctAnswer.url} hidden />
      <InfoPanel>
        <div>{title}</div>
        <div>Time: {format(progress)}</div>
      </InfoPanel>
      <ButtonRow>
        <Tippy
          content={
            !!guess.time
              ? "Enter your guess or skip to play again"
              : "Hold to play song"
          }
        >
          <CueButton
            onPointerDown={handlePointerDown}
            onPointerUp={stop}
            onPointerLeave={stop}
            isDisabled={!!guess.time}
            isPlaying={isPlaying}
          >
            Cue
          </CueButton>
        </Tippy>
        <EjectButton to="/">
          <Eject />
        </EjectButton>
      </ButtonRow>
      <Guesses
        correctAnswer={correctAnswer}
        turn={turn}
        songs={songs}
        attempts={attempts}
        setAttempts={setAttempts}
        guess={guess}
        setGuess={setGuess}
      />
    </>
  );
};
