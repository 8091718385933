import { Divider } from "@mui/material";
import ReactModal from "react-modal";
import styled from "styled-components";
import { Close, Calendar, Edit, Music, Share } from "../icons";

ReactModal.setAppElement("#root");

const HowToPlayModalWrapper = styled.div`
  text-align: center;
  max-width: 100%;
  position: relative;
  padding: 15px;
  width: 500px;
`;

const CloseButton = styled.button`
  padding: 0;
  border: 0;
  background: 0;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const StepContainer = styled.div`
  padding: 30px 15px 15px 15px;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  text-align: left;

  svg {
    margin-right: 10px;
  }
`;

export const HowToPlayModal = ({ close, ...props }) => {
  return (
    <ReactModal
      {...props}
      className="ReactModal__Modal"
      overlayClassName="ReactModal__Overlay"
      shouldCloseOnOverlayClick
      onRequestClose={close}
    >
      <HowToPlayModalWrapper>
        <CloseButton onClick={close}>
          <Close />
        </CloseButton>
        <div>
          <h2>How to Play</h2>
          <Divider />
          <StepContainer>
            <Step>
              <Calendar /> Choose a decade
            </Step>
            <Step>
              <Music /> Hold the CUE button to play the song
            </Step>
            <Step>
              <Edit /> When you know what the song is, take a guess
            </Step>
            <Step>
              <Share /> Share with your friends!
            </Step>
          </StepContainer>
          <Divider />
          <p>The goal is to guess the song in the least amount of time.</p>
        </div>
      </HowToPlayModalWrapper>
    </ReactModal>
  );
};
