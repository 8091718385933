import copy from "copy-to-clipboard";
import ReactModal from "react-modal";
import styled, { css } from "styled-components";
import { maxBy } from "lodash";
import { Close } from "../icons";
import { Player } from "./Player";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

ReactModal.setAppElement("#root");

const ResultsModalWrapper = styled.div`
  text-align: center;
  max-width: 100%;
  position: relative;
  padding: 15px;
  width: 500px;
`;

const CloseButton = styled.button`
  padding: 0;
  border: 0;
  background: 0;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const ButtonRow = styled.div`
  padding-top: 25px;
`;

const buttonStyles = css`
  border: 0;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.15s;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 15px;

  &:first-of-type {
    margin-right: 15px;
  }

  &:hover {
    background-color: #444;
  }
`;

const CorrectAnswer = styled.div`
  background-color: #fafafa;
  border-radius: 5px;
  padding: 10px 5px;
  margin-top: 20px;
`;

const LinkButton = styled(Link)`
  ${buttonStyles};
`;

const Button = styled.button`
  ${buttonStyles};
`;

export const ResultsModal = ({
  attempts,
  close,
  correctAnswer,
  result,
  decade,
  dayIndex,
  ...props
}) => {
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const playerRef = useRef();

  const copyResults = () => {
    const guesses = attempts.map(({ value }) => {
      if (!value) return "⬛";
      if (value === correctAnswer.label) return "🟩";
      return "🟥";
    });

    const maxTime = Math.floor(maxBy(attempts, "time").time / 100) / 10;

    copy(
      `#CUEdle #${decade} #${dayIndex}\n\n${guesses.join(" ")} ${maxTime}s\n\n${
        document.location.origin
      }`
    );
    setShowCopySuccess(true);
  };

  useEffect(() => {
    if (!showCopySuccess) return;
    setTimeout(() => {
      setShowCopySuccess(false);
    }, 1000);
  }, [showCopySuccess]);

  return (
    <ReactModal
      {...props}
      className="ReactModal__Modal"
      overlayClassName="ReactModal__Overlay"
      shouldCloseOnOverlayClick
      onRequestClose={close}
    >
      <ResultsModalWrapper>
        <CloseButton onClick={close}>
          <Close />
        </CloseButton>
        <h1>{result === "win" ? "Amazing 🎉" : "Oh dear 😢"}</h1>
        <h3>
          {result === "win"
            ? "You have done it again, constantly raising the bar for us all - and doing it flawlessly."
            : "Hey, at least you tried."}
        </h3>
        <Player
          ref={playerRef}
          url={`${correctAnswer.url}&color=%23ff5500&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false`}
        />
        <CorrectAnswer>{correctAnswer.label}</CorrectAnswer>
        <ButtonRow>
          <LinkButton to="/">Try Another Decade</LinkButton>
          <Button onClick={copyResults}>
            {showCopySuccess ? "Copied to Clipbord" : "Share Your Results"}
          </Button>
        </ButtonRow>
      </ResultsModalWrapper>
    </ReactModal>
  );
};
