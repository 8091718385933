import styled, { css } from "styled-components";

export const CueButton = styled.button`
  ${({ isDisabled, isPlaying }) => css`
    font-weight: bold;
    letter-spacing: 3px;
    height: 80px;
    display: block;
    width: calc(100% - 10px);
    border-radius: 7.5px;
    margin: 5px;
    margin-bottom: 25px;
    text-transform: uppercase;
    border: 0;
    background-color: #333333;
    color: ${isDisabled ? "#777777" : "white"};
    text-shadow: ${isDisabled ? "none" : "0 0 10px rgba(255, 255, 255, 0.5)"};
    font-size: 1.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: text-shadow 0.25s, background-color 0.25s, color 0.25s;
    cursor: ${isDisabled ? "not-allowed" : "pointer"};
    position: relative;
    user-select: none;

    &:hover {
      background-color: ${isDisabled ? "#333333" : "#444444"};
    }

    &:before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      bottom: -5px;
      right: -5px;
      background: ${isDisabled ? "#f53c27" : isPlaying ? "#27f52e" : "#f5d327"};
      z-index: -1;
      border-radius: 10px;
      box-shadow: 0 0 7px 0
        ${isDisabled ? "#f53c27" : isPlaying ? "#27f52e" : "#f5d327"};
      transition: background 0.25s, box-shadow 0.25s;
    }
  `}
`;
