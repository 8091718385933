import { forwardRef } from "react";

export const Player = forwardRef((props, ref) => (
  <iframe
    frameBorder={0}
    width="100%"
    hidden={props.hidden}
    ref={ref}
    allow="autoplay"
    title="player"
    src={`https://w.soundcloud.com/player/?url=${props.url}`}
  />
));
