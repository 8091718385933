import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, Decade } from "./views";
import { GlobalStyles } from "./styles";
import { songs } from "./data";
import styled from "styled-components";
import { Header } from "./components";

import brushedmetal from "./assets/brushedmetal.png";

const AppWrapper = styled.div`
  background: #bcc6cc;
  background-image: url("${brushedmetal}");
  background-size: cover;
  background-blend-mode: soft-light;
  border: 1px solid #bbb;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  color: black;
  margin: 30px auto;
  max-width: 100%;
  padding: 30px 45px 15px 45px;
  position: relative;
  width: 550px;
  z-index: 0;

  &:before,
  &:after {
    background: #444;
    bottom: -3px;
    content: "";
    position: absolute;
    top: -3px;
    width: 7px;
    border-radius: 2px;
  }

  &:before {
    left: 20px;
  }

  &:after {
    right: 20px;
  }
`;

export const App = () => (
  <BrowserRouter>
    <GlobalStyles />
    <AppWrapper>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/decade">
          <Route
            path="2020s"
            element={
              <Decade
                songs={songs["2020s"]}
                decade="2020s"
                title="2020's Hits"
              />
            }
          />
          <Route
            path="2010s"
            element={
              <Decade
                songs={songs["2010s"]}
                decade="2010s"
                title="2010 Throwbacks"
              />
            }
          />
          <Route
            path="2000s"
            element={
              <Decade
                songs={songs["2000s"]}
                decade="2000s"
                title="Now it's the 00's"
              />
            }
          />
          <Route
            path="1990s"
            element={
              <Decade
                songs={songs["1990s"]}
                decade="1990s"
                title="Back to the Nineties"
              />
            }
          />
          <Route
            path="1980s"
            element={
              <Decade
                songs={songs["1980s"]}
                decade="1980s"
                title="The Ultimate 80's"
              />
            }
          />
          <Route
            path="1970s"
            element={
              <Decade
                songs={songs["1970s"]}
                decade="1970s"
                title="Seventies Essentials"
              />
            }
          />
        </Route>
      </Routes>
    </AppWrapper>
  </BrowserRouter>
);
