import ReactModal from "react-modal";
import styled from "styled-components";
import { Close } from "../icons";

ReactModal.setAppElement("#root");

const StatsModalWrapper = styled.div`
  text-align: center;
  max-width: 100%;
  position: relative;
  padding: 15px;
  padding-bottom: 45px;
  width: 500px;
`;

const CloseButton = styled.button`
  padding: 0;
  border: 0;
  background: 0;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Statistic = styled.div`
  display: inline-block;
  background: #eaeaea;
  margin-bottom: 15px;
  padding: 20px;
  margin: 15px;
  border-radius: 15px;
`;

const StatHeader = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
`;

export const StatsModal = ({ close, ...props }) => {
  const data = {
    "1970s": JSON.parse(localStorage.getItem("data.1970s")),
    "1980s": JSON.parse(localStorage.getItem("data.1980s")),
    "1990s": JSON.parse(localStorage.getItem("data.1990s")),
    "2000s": JSON.parse(localStorage.getItem("data.2000s")),
    "2010s": JSON.parse(localStorage.getItem("data.2010s")),
    "2020s": JSON.parse(localStorage.getItem("data.2020s")),
  };

  const displayTotal = (decade) => {
    if (!data[decade]) return;
    const keys = Object.keys(data[decade]);
    const total = keys.filter((key) => !!data[decade][key].result).length;
    const wins = keys.filter(
      (key) => data[decade][key].result === "win"
    ).length;

    if (total === 0) return;

    return (
      <Statistic>
        <StatHeader>{decade}</StatHeader>
        {wins}/{total} correct
      </Statistic>
    );
  };

  return (
    <ReactModal
      {...props}
      className="ReactModal__Modal"
      overlayClassName="ReactModal__Overlay"
      shouldCloseOnOverlayClick
      onRequestClose={close}
    >
      <StatsModalWrapper>
        <CloseButton onClick={close}>
          <Close />
        </CloseButton>
        <h2>Statistics</h2>
        <StatsContainer>
          {displayTotal("1970s")}
          {displayTotal("1980s")}
          {displayTotal("1990s")}
          {displayTotal("2000s")}
          {displayTotal("2010s")}
          {displayTotal("2020s")}
        </StatsContainer>
      </StatsModalWrapper>
    </ReactModal>
  );
};
