import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyles = createGlobalStyle`
    ${normalize}

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    body {
        font-family: 'Roboto', sans-serif;
    }

    form {
        display: inline;
    }

    svg {
        vertical-align: middle;
    }

    button {
        cursor: pointer;
    }

    .ReactModal__Modal {
        background-color: white;
        max-width: 100%;
        border-radius: 5px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
  
    .ReactModal__Overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25);
      padding: 30px 15px;
      align-items: flex-start;
      display: flex;
      justify-content: center;
      backdrop-filter: blur(2.5px);
    }
`;
