import loadScript from "load-script";
import { useEffect, useState } from "react";

export const usePlayer = (ref, onStop) => {
  const [player, setPlayer] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    loadScript("https://w.soundcloud.com/player/api.js", () => {
      const player = window.SC.Widget(ref.current);
      setPlayer(player);

      const { PLAY_PROGRESS, FINISH } = window.SC.Widget.Events;
      player.bind(PLAY_PROGRESS, handlePlayProgress);
      player.bind(FINISH, stop);
    });
  }, [ref]);

  const start = () => {
    player.play();
    setIsPlaying(true);
  };

  const stop = () => {
    if (!isPlaying) return;
    player.pause();
    onStop(progress);
    setIsPlaying(false);
  };

  const seek = (position) => {
    setProgress(position);
    player.seekTo(position);
  };

  const handlePlayProgress = ({ currentPosition }) =>
    setProgress(currentPosition);

  return { isPlaying, seek, start, stop, progress, loaded: !!player };
};
