import { inputStyles } from "./Guesses";
import { sortBy } from "lodash";
import styled from "styled-components";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import { Skip } from "../icons";

const Input = styled.input`
  ${inputStyles}
  border: 1px solid #aaa;
`;

const SongInputWrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: relative;
`;

const SkipButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    svg {
      fill: #666;
    }
  }

  svg {
    vertical-align: middle;
    fill: #333;
    transition: fill 0.25s;
  }
`;

const OptionsWrapper = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 5px);
  background: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  max-height: 200px;
  overflow-y: auto;
`;

const Option = styled.li`
  display: block;
  padding: 7.5px;

  &:hover {
    background-color: #eaeaea;
  }
`;

export const SongInput = ({ onSubmit, skip, songs }) => {
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    inputValue,
  } = useAutocomplete({
    id: "song-input",
    options: sortBy(songs, (song) => song.label),
    openOnFocus: false,
    getOptionLabel: (song) => song.label,
  });

  return (
    <SongInputWrapper>
      <Container {...getRootProps()}>
        <Input
          active
          {...getInputProps()}
          placeholder="Search for the song..."
        />
        <SkipButton onClick={skip}>
          <Skip />
        </SkipButton>
      </Container>
      {groupedOptions.length > 0 && inputValue ? (
        <OptionsWrapper {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <Option
              {...getOptionProps({ option, index })}
              data-song={option.label}
              onClick={onSubmit}
            >
              {option.label}
            </Option>
          ))}
        </OptionsWrapper>
      ) : null}
    </SongInputWrapper>
  );
};
